import { ProColumns } from "@ant-design/pro-components";
import { textToIdentificationNumber } from "../utils";

export const royaltiesNotesDataColumns: ProColumns[] = [
  {
    title: "id",
    dataIndex: "id",
    hidden: true,
    hideInSearch: true,
  },
  {
    dataIndex: "saleType",
    title: "Tipo da Venda",
    hidden: false,
    width: 200,
    hideInSearch: true,
  },
  {
    dataIndex: "invoice",
    title: "Nota Fiscal",
    hidden: false,
    width: 100,
    hideInSearch: false,
  },
  {
    dataIndex: "batch",
    title: "Lote",
    hidden: false,
    width: 100,
    hideInSearch: false,
  },
  {
    dataIndex: "quantity",
    title: "Quantidade",
    hidden: false,
    width: 80,
    hideInSearch: true,
  },
  {
    dataIndex: "technology",
    title: "Tecnologia",
    hidden: false,
    width: 80,
    hideInSearch: true,
  },
  {
    dataIndex: "buyerName",
    title: "Nome do Comprador",
    hidden: false,
    width: 200,
    hideInSearch: true,
  },
  {
    dataIndex: "buyerIdentifierNumber",
    title: "Nº Doc CNPJ/CPF Comprador",
    hidden: false,
    width: 200,
    renderText: (value) => textToIdentificationNumber(value),
    hideInSearch: false,
  },
  {
    dataIndex: "product",
    title: "Produto",
    hidden: false,
    width: 200,
    hideInSearch: true,
  },
  {
    dataIndex: "sellerIdentifierNumber",
    title: "Nº Doc CNPJ/CPF do Vendedor",
    hidden: false,
    width: 200,
    renderText: (text) => textToIdentificationNumber(text),
    hideInSearch: true,
  },
  {
    dataIndex: "sellerName",
    title: "Nome do Vendedor",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "invoiceDate",
    title: "Data da Nota Fiscal",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "creationDate",
    title: "Data de Criação",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },

  {
    dataIndex: "creditVolume",
    title: "Volume de Crédito",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "netUnitPrice",
    title: "Valor Unitário Liquido (R$)",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "calculatedNetValue",
    title: "Valor Calculado Liquido (NET) (R$)",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "billingType",
    title: "Tipo de Cobrança",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "dueDate",
    title: "Data de vencimento",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "plantingState",
    title: "Estado de Plantio",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "plantingCity",
    title: "Cidade de Plantio",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "harvest",
    title: "Safra",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "brand",
    title: "Marca",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },

  {
    dataIndex: "saleStatus",
    title: "Status da Venda",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "sellerState",
    title: "UF do Vendedor",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "sellerCity",
    title: "Cidade do Vendedor",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
];
