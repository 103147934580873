import React from "react";
import { Tabs } from "antd";
import { useAsync } from "react-use";

import { useAPI } from "../../../client-api/utils/api";
import { useGlobalApp } from "../../../components/global-app-provider";
import { MapperTransformApi } from "../../../client-api";
import ImportCard from "./import-card";
import { ReportType } from "./tag-report-type";
import billingExtraInfo from "./billing-extra-Info";

const createMapperGroup = (mappers?: Array<object>) => {
  return mappers?.reduce((mapperGroup: any, mapper: any) => {
    const item = { value: mapper.id, label: mapper.name };
    if (!mapperGroup[mapper.reportType])
      mapperGroup[mapper.reportType] = [item];
    else mapperGroup[mapper.reportType].push(item);
    return mapperGroup;
  }, {});
};

const ImportFiles: React.FC = () => {
  const { getOwner } = useGlobalApp();
  const api = useAPI(MapperTransformApi);
  const currentOwner = getOwner();

  const state = useAsync(async () => {
    return await api.mapperTransformControllerFindAll({
      ownerid: currentOwner !== false ? Number(currentOwner.id) : 1,
    });
  }, []);

  const mappers = createMapperGroup(state.value?.list);

  const importersItems = [
    {
      label: `Faturamento do cliente`,
      key: "1",
      children: (
        <ImportCard
          title="Arquivo de faturamento do cliente"
          description="Arquivo conteudo todos os registros dos itens da notas fiscais
    faturadas pelo cliente, essa base será utilizada para gerar os
    registros a serem reportadors no sistema de controle de Royalties"
          urlTemplate={"/files/templates/faturamento_template.xlsx"}
          mappers={mappers?.[ReportType.BILLING] || []}
          reporttype={ReportType.BILLING}
          ownerId={!!currentOwner ? currentOwner.id : -1}
          extraInfo={billingExtraInfo}
        />
      ),
    },
    {
      label: `Notas de devolução`,
      key: "2",
      children: (
        <ImportCard
          title="Arquivo de devoluções do cliente"
          description="Arquivo conteudo todos os registros de devoluções de itens de notas fiscais
    faturadas pelo cliente, essa base será utilizada para gerar os
    registros a serem reportadors no sistema de controle de Royalties"
          urlTemplate={"/files/templates/devolucao_template.xlsx"}
          mappers={mappers?.[ReportType.DEVOLUTION] || []}
          reporttype={ReportType.DEVOLUTION}
          ownerId={!!currentOwner ? currentOwner.id : -1}
        />
      ),
    },
    {
      label: `Relatório ITS`,
      key: "3",
      children: (
        <ImportCard
          title="Relatorio ITS"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sit
    amet condimentum lorem. Pellentesque gravida orci ac ipsum pulvinar,
    et auctor lectus blandit. Quisque et sapien sit amet nibh venenatis"
          urlTemplate={[
            "/files/templates/its_bayer_template.xlsx",
            "/files/templates/its_corteva_template.xlsx",
          ]}
          mappers={mappers?.[ReportType.ITS] || []}
          reporttype={ReportType.ITS}
          ownerId={!!currentOwner ? currentOwner.id : -1}
        />
      ),
    },
    {
      label: `Cotas`,
      key: "4",
      disabled: false,
      children: (
        <ImportCard
          title="Relatorio de Cotas (ITS)"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sit
    amet condimentum lorem. Pellentesque gravida orci ac ipsum pulvinar,
    et auctor lectus blandit. Quisque et sapien sit amet nibh venenatis"
          urlTemplate={"/files/templates/its__quotes_bayer_template.xlsx"}
          mappers={mappers?.[ReportType.ITS_QUOTAS] || []}
          reporttype={ReportType.ITS_QUOTAS}
          ownerId={!!currentOwner ? currentOwner.id : -1}
        />
      ),
    },
  ];

  return <Tabs tabPosition={"left"} items={importersItems} />;
};

export default ImportFiles;
