/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PaginationResponseDto,
} from '../models/index';
import {
    PaginationResponseDtoFromJSON,
    PaginationResponseDtoToJSON,
} from '../models/index';

export interface HarvestControllerFindAllRequest {
    pageSize?: number;
    page?: number;
    name?: string;
    slug?: string;
}

/**
 * 
 */
export class HarvestApi extends runtime.BaseAPI {

    /**
     */
    async harvestControllerFindAllRaw(requestParameters: HarvestControllerFindAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginationResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['slug'] != null) {
            queryParameters['slug'] = requestParameters['slug'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/harvest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async harvestControllerFindAll(requestParameters: HarvestControllerFindAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginationResponseDto> {
        const response = await this.harvestControllerFindAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
