import React, { useState } from "react";
import type { GetProp, TableProps } from "antd";
import { Space, Table, Tabs, Tag, Typography } from "antd";
import type { SorterResult } from "antd/es/table/interface";
import { useAPI } from "../../../client-api/utils/api";
import { SourceApi } from "../../../client-api";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useInterval, useAsyncRetry } from "react-use";
import { formatDate } from "../../../components/utils";
import TagReportType, { ReportType } from "./tag-report-type";

type ColumnsType<T extends object = object> = TableProps<T>["columns"];
type TablePaginationConfig = Exclude<
  GetProp<TableProps, "pagination">,
  boolean
>;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: SorterResult<any>["field"];
  sortOrder?: SorterResult<any>["order"];
  filters?: Parameters<GetProp<TableProps, "onChange">>[1];
}

const columns: ColumnsType = [
  {
    title: "Nome",
    dataIndex: "fileOriginName",
    width: "20%",
  },
  {
    title: "Tipo",
    dataIndex: "reportType",
    render(value: string) {
      return <TagReportType type={value} />;
    },
  },
  {
    title: "Criado em",
    dataIndex: "createdAt",
    render(value, record: any, index) {
      return (
        <Space direction="vertical">
          <Typography.Text>{formatDate(value)}</Typography.Text>
          <Typography.Text type="secondary">
            <Typography.Text type="secondary" strong>
              por:
            </Typography.Text>
            {record?.createdBy?.name || "--"}
          </Typography.Text>
        </Space>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "20%",
    render: (value) => {
      if (value === "failed")
        return (
          <Tag icon={<CloseCircleOutlined />} color="error">
            Falhou
          </Tag>
        );
      if (value === "finished") {
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Finalizado
          </Tag>
        );
      }
      if (value === "importing") {
        return (
          <Tag icon={<SyncOutlined spin />} color="processing">
            Em processo
          </Tag>
        );
      }

      if (value === "queued") {
        return (
          <Tag icon={<ExclamationCircleOutlined spin />} color="processing">
            Aguardando
          </Tag>
        );
      }
      return value;
    },
  },
  {
    title: "Info",
    dataIndex: "data",
    render: (value) => {
      return (
        <Typography.Paragraph
          style={{ whiteSpace: "pre-wrap" }}
          ellipsis={{ rows: 5 }}
          copyable
        >
          {value?.message}
        </Typography.Paragraph>
      );
    },
  },
];

const ProcessList: React.FC = () => {
  const api = useAPI(SourceApi);

  const [activeTabKey, setActiveTabKey] = useState<string>("all");

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const state = useAsyncRetry(async () => {
    return api.sourceControllerFindAll({
      reporttype: activeTabKey !== "all" ? (activeTabKey as any) : undefined,
      page: tableParams.pagination?.current,
      pageSize: tableParams.pagination?.pageSize,
    });
  }, [
    activeTabKey,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
  ]);

  useInterval(state.retry, 7000);

  const handleTableChange: TableProps["onChange"] = (pagination, filters) => {
    setTableParams({
      pagination,
      filters,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setTableParams({
        pagination: {
          ...pagination,
          current: 1,
        },
      });
    }
  };

  const renderTable = (
    <Table
      columns={columns}
      rowKey={"id"}
      dataSource={state.value?.list}
      pagination={{
        ...tableParams.pagination,
        total: state.value?.total,
      }}
      loading={state.value?.list?.length === 0 && state.loading}
      onChange={handleTableChange}
    />
  );

  const tabs = [
    {
      label: `Todos`,
      key: "all",
      children: renderTable,
    },
    {
      label: `Faturamento`,
      key: ReportType.BILLING,
      children: renderTable,
    },
    {
      label: `Devolução`,
      key: ReportType.DEVOLUTION,
      children: renderTable,
    },
    {
      label: `ITS`,
      key: ReportType.ITS,
      children: renderTable,
    },
    {
      label: `Cotas`,
      key: ReportType.ITS_QUOTAS,
      children: renderTable,
    },
  ];

  return (
    <>
      <Tabs
        items={tabs}
        activeKey={activeTabKey}
        tabPosition={"left"}
        onChange={(e) => setActiveTabKey(e)}
      />
    </>
  );
};

export default ProcessList;
