import EditDataFields from "../../pages/client-billing/data-fields";
import TagInvoiceStatus from "../invoices-status/tag-invoice-status";
import ClientBillingEdit from "../client-billing-edit/indes";
import { EllipsisOutlined } from "@ant-design/icons";
import type { ProColumns } from "@ant-design/pro-components";
import { textToIdentificationNumber } from "../utils";
import TagInvoiceStatusDescription from "../invoices-status/tag-invoice-status-description";

export const clientBillingColumns: ProColumns[] = [
  {
    title: "id",
    dataIndex: "id",
    hidden: true,
    hideInSearch: true,
  },
  {
    title: <EllipsisOutlined />,
    fixed: "right",
    key: "options",
    width: 64,
    render: (_, record) => (
      <ClientBillingEdit
        filters={{ id: record.id }}
        iconMode
        // onConfirm={() => actionRefInvoicesNotes?.current?.reload()}
      />
    ),
  },
  {
    title: EditDataFields["status"],
    dataIndex: "status",
    width: 100,
    renderText: (value: string) => (
      <>
        <TagInvoiceStatus value={value} />
      </>
    ),
  },
  {
    title: "Descrição",
    key: "statusCategory",
    dataIndex: "statusCategory",
    width: 100,
    renderText: (value: string, record) => (
      <TagInvoiceStatusDescription value={value} status={record.status} />
    ),
  },
  {
    title: EditDataFields["editDataNfe"],
    dataIndex: "editDataNfe",
    ellipsis: true,
    width: 100,
  },
  {
    title: EditDataFields["editDataBatch"],
    dataIndex: "editDataBatch",
    ellipsis: true,
    width: 100,
  },
  {
    title: EditDataFields["editDataWeightKq"],
    dataIndex: "editDataWeightKq",
    ellipsis: true,
    width: 80,
  },
  {
    title: EditDataFields["editDataTechnology"],
    dataIndex: "editDataTechnology",
    ellipsis: true,
    width: 80,
  },
  {
    title: EditDataFields["editDataClientName"],
    dataIndex: "editDataClientName",
    ellipsis: true,
    width: 200,
  },
  {
    title: EditDataFields["editDataIdentifierNumber"],
    dataIndex: "editDataIdentifierNumber",
    ellipsis: true,
    width: 200,
    renderText: (text) => textToIdentificationNumber(text),
  },
  {
    title: EditDataFields["editDataCultivate"],
    dataIndex: "editDataCultivate",
    ellipsis: true,
    width: 200,
  },
  {
    title: EditDataFields["editDataMultiplierIdentifierNumber"],
    dataIndex: "editDataMultiplierIdentifierNumber",
    width: 200,
    renderText: (text) => textToIdentificationNumber(text),
  },
  {
    title: EditDataFields["editDataSeedUse"],
    dataIndex: "editDataSeedUse",
    ellipsis: true,
    width: 120,
  },
  {
    title: EditDataFields["editDataCfop"],
    dataIndex: "editDataCfop",
    width: 80,
  },
  {
    title: EditDataFields["editDataUf"],
    dataIndex: "editDataUf",
    ellipsis: true,
    width: 30,
  },
  {
    title: EditDataFields["editDataCity"],
    dataIndex: "editDataCity",
    ellipsis: true,
    width: 150,
  },
  {
    title: EditDataFields["editDataBillingDate"],
    dataIndex: "editDataBillingDate",
    ellipsis: true,
    width: 90,
  },

  {
    title: EditDataFields["editDataQtyPackaging"],
    dataIndex: "editDataQtyPackaging",
    ellipsis: true,
    width: 80,
  },

  {
    title: EditDataFields["editDataWeightPackaging"],
    dataIndex: "editDataWeightPackaging",
    ellipsis: true,
    width: 80,
  },
];
