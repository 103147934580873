import { Select } from "antd";
import { InvoiceStatusDef } from "./types";
import { SelectProps } from "antd/lib";

const SelectInvoiceStatus = (props: SelectProps) => {
  const options = Object.keys(InvoiceStatusDef).map((key) => ({
    label: InvoiceStatusDef[key].label,
    value: key,
  }));
  return <Select {...props} options={options} />;
};

export default SelectInvoiceStatus;
