import { Tag } from "antd";

export enum ReportType {
  BILLING = "client_billing",
  DEVOLUTION = "client_devolution",
  ITS = "royalties_notes",
  ITS_QUOTAS = "its_quotas",
}

const TagReportType = ({ type }: { type: string }) => {
  let label = "Desconhecido";
  if (type === ReportType.BILLING) label = `Faturamento`;
  if (type === ReportType.DEVOLUTION) label = `Devolução`;
  if (type === ReportType.ITS) label = `ITS`;
  if (type === ReportType.ITS_QUOTAS) label = `Quotas`;

  return <Tag>{label}</Tag>;
};

export default TagReportType;
