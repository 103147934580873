/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BgProcessDto,
  DevolutionsInvoicesNotesTotalsDto,
  PaginationResponseDto,
  UpdateDevolutionsInvoicesNotesDataDto,
  UpdateResponseDto,
} from '../models/index';
import {
    BgProcessDtoFromJSON,
    BgProcessDtoToJSON,
    DevolutionsInvoicesNotesTotalsDtoFromJSON,
    DevolutionsInvoicesNotesTotalsDtoToJSON,
    PaginationResponseDtoFromJSON,
    PaginationResponseDtoToJSON,
    UpdateDevolutionsInvoicesNotesDataDtoFromJSON,
    UpdateDevolutionsInvoicesNotesDataDtoToJSON,
    UpdateResponseDtoFromJSON,
    UpdateResponseDtoToJSON,
} from '../models/index';

export interface DevolutionsInvoicesNotesDataControllerExportAllRequest {
    xHarvest?: string;
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    id?: number;
    status?: Array<string>;
    dataMultiplierIdentifierNumber?: string;
    dataNfe?: string;
    dataIdentifierNumber?: string;
    dataDevolutionNfe?: string;
    dataCustomerName?: string;
    dataBatch?: string;
    dataWeightKq?: string;
    editDataMultiplierIdentifierNumber?: string;
    editDataNfe?: string;
    editDataIdentifierNumber?: string;
    editDataWeightKq?: string;
    editDataDevolutionNfe?: string;
    editDataCustomerName?: string;
    editDataBatch?: string;
    keyIntegration?: string;
}

export interface DevolutionsInvoicesNotesDataControllerFindAllRequest {
    xHarvest?: string;
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    id?: number;
    status?: Array<string>;
    dataMultiplierIdentifierNumber?: string;
    dataNfe?: string;
    dataIdentifierNumber?: string;
    dataDevolutionNfe?: string;
    dataCustomerName?: string;
    dataBatch?: string;
    dataWeightKq?: string;
    editDataMultiplierIdentifierNumber?: string;
    editDataNfe?: string;
    editDataIdentifierNumber?: string;
    editDataWeightKq?: string;
    editDataDevolutionNfe?: string;
    editDataCustomerName?: string;
    editDataBatch?: string;
    keyIntegration?: string;
}

export interface DevolutionsInvoicesNotesDataControllerFindAllWithoutInvoicesRequest {
    xHarvest?: string;
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    id?: number;
    status?: Array<string>;
    dataMultiplierIdentifierNumber?: string;
    dataNfe?: string;
    dataIdentifierNumber?: string;
    dataDevolutionNfe?: string;
    dataCustomerName?: string;
    dataBatch?: string;
    dataWeightKq?: string;
    editDataMultiplierIdentifierNumber?: string;
    editDataNfe?: string;
    editDataIdentifierNumber?: string;
    editDataWeightKq?: string;
    editDataDevolutionNfe?: string;
    editDataCustomerName?: string;
    editDataBatch?: string;
    keyIntegration?: string;
}

export interface DevolutionsInvoicesNotesDataControllerGetTotalsRequest {
    xHarvest?: string;
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    id?: number;
    status?: Array<string>;
    dataMultiplierIdentifierNumber?: string;
    dataNfe?: string;
    dataIdentifierNumber?: string;
    dataDevolutionNfe?: string;
    dataCustomerName?: string;
    dataBatch?: string;
    dataWeightKq?: string;
    editDataMultiplierIdentifierNumber?: string;
    editDataNfe?: string;
    editDataIdentifierNumber?: string;
    editDataWeightKq?: string;
    editDataDevolutionNfe?: string;
    editDataCustomerName?: string;
    editDataBatch?: string;
    keyIntegration?: string;
}

export interface DevolutionsInvoicesNotesDataControllerUpdateRequest {
    updateDevolutionsInvoicesNotesDataDto: UpdateDevolutionsInvoicesNotesDataDto;
    xHarvest?: string;
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    id?: number;
    status?: Array<string>;
    dataMultiplierIdentifierNumber?: string;
    dataNfe?: string;
    dataIdentifierNumber?: string;
    dataDevolutionNfe?: string;
    dataCustomerName?: string;
    dataBatch?: string;
    dataWeightKq?: string;
    editDataMultiplierIdentifierNumber?: string;
    editDataNfe?: string;
    editDataIdentifierNumber?: string;
    editDataWeightKq?: string;
    editDataDevolutionNfe?: string;
    editDataCustomerName?: string;
    editDataBatch?: string;
    keyIntegration?: string;
}

/**
 * 
 */
export class DevolutionsInvoicesNotesDataApi extends runtime.BaseAPI {

    /**
     */
    async devolutionsInvoicesNotesDataControllerExportAllRaw(requestParameters: DevolutionsInvoicesNotesDataControllerExportAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BgProcessDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['dataMultiplierIdentifierNumber'] != null) {
            queryParameters['dataMultiplierIdentifierNumber'] = requestParameters['dataMultiplierIdentifierNumber'];
        }

        if (requestParameters['dataNfe'] != null) {
            queryParameters['dataNfe'] = requestParameters['dataNfe'];
        }

        if (requestParameters['dataIdentifierNumber'] != null) {
            queryParameters['dataIdentifierNumber'] = requestParameters['dataIdentifierNumber'];
        }

        if (requestParameters['dataDevolutionNfe'] != null) {
            queryParameters['dataDevolutionNfe'] = requestParameters['dataDevolutionNfe'];
        }

        if (requestParameters['dataCustomerName'] != null) {
            queryParameters['dataCustomerName'] = requestParameters['dataCustomerName'];
        }

        if (requestParameters['dataBatch'] != null) {
            queryParameters['dataBatch'] = requestParameters['dataBatch'];
        }

        if (requestParameters['dataWeightKq'] != null) {
            queryParameters['dataWeightKq'] = requestParameters['dataWeightKq'];
        }

        if (requestParameters['editDataMultiplierIdentifierNumber'] != null) {
            queryParameters['editDataMultiplierIdentifierNumber'] = requestParameters['editDataMultiplierIdentifierNumber'];
        }

        if (requestParameters['editDataNfe'] != null) {
            queryParameters['editDataNfe'] = requestParameters['editDataNfe'];
        }

        if (requestParameters['editDataIdentifierNumber'] != null) {
            queryParameters['editDataIdentifierNumber'] = requestParameters['editDataIdentifierNumber'];
        }

        if (requestParameters['editDataWeightKq'] != null) {
            queryParameters['editDataWeightKq'] = requestParameters['editDataWeightKq'];
        }

        if (requestParameters['editDataDevolutionNfe'] != null) {
            queryParameters['editDataDevolutionNfe'] = requestParameters['editDataDevolutionNfe'];
        }

        if (requestParameters['editDataCustomerName'] != null) {
            queryParameters['editDataCustomerName'] = requestParameters['editDataCustomerName'];
        }

        if (requestParameters['editDataBatch'] != null) {
            queryParameters['editDataBatch'] = requestParameters['editDataBatch'];
        }

        if (requestParameters['keyIntegration'] != null) {
            queryParameters['keyIntegration'] = requestParameters['keyIntegration'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xHarvest'] != null) {
            headerParameters['x-harvest'] = String(requestParameters['xHarvest']);
        }

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/invoices/devolutions/notes/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BgProcessDtoFromJSON(jsonValue));
    }

    /**
     */
    async devolutionsInvoicesNotesDataControllerExportAll(requestParameters: DevolutionsInvoicesNotesDataControllerExportAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BgProcessDto> {
        const response = await this.devolutionsInvoicesNotesDataControllerExportAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async devolutionsInvoicesNotesDataControllerFindAllRaw(requestParameters: DevolutionsInvoicesNotesDataControllerFindAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginationResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['dataMultiplierIdentifierNumber'] != null) {
            queryParameters['dataMultiplierIdentifierNumber'] = requestParameters['dataMultiplierIdentifierNumber'];
        }

        if (requestParameters['dataNfe'] != null) {
            queryParameters['dataNfe'] = requestParameters['dataNfe'];
        }

        if (requestParameters['dataIdentifierNumber'] != null) {
            queryParameters['dataIdentifierNumber'] = requestParameters['dataIdentifierNumber'];
        }

        if (requestParameters['dataDevolutionNfe'] != null) {
            queryParameters['dataDevolutionNfe'] = requestParameters['dataDevolutionNfe'];
        }

        if (requestParameters['dataCustomerName'] != null) {
            queryParameters['dataCustomerName'] = requestParameters['dataCustomerName'];
        }

        if (requestParameters['dataBatch'] != null) {
            queryParameters['dataBatch'] = requestParameters['dataBatch'];
        }

        if (requestParameters['dataWeightKq'] != null) {
            queryParameters['dataWeightKq'] = requestParameters['dataWeightKq'];
        }

        if (requestParameters['editDataMultiplierIdentifierNumber'] != null) {
            queryParameters['editDataMultiplierIdentifierNumber'] = requestParameters['editDataMultiplierIdentifierNumber'];
        }

        if (requestParameters['editDataNfe'] != null) {
            queryParameters['editDataNfe'] = requestParameters['editDataNfe'];
        }

        if (requestParameters['editDataIdentifierNumber'] != null) {
            queryParameters['editDataIdentifierNumber'] = requestParameters['editDataIdentifierNumber'];
        }

        if (requestParameters['editDataWeightKq'] != null) {
            queryParameters['editDataWeightKq'] = requestParameters['editDataWeightKq'];
        }

        if (requestParameters['editDataDevolutionNfe'] != null) {
            queryParameters['editDataDevolutionNfe'] = requestParameters['editDataDevolutionNfe'];
        }

        if (requestParameters['editDataCustomerName'] != null) {
            queryParameters['editDataCustomerName'] = requestParameters['editDataCustomerName'];
        }

        if (requestParameters['editDataBatch'] != null) {
            queryParameters['editDataBatch'] = requestParameters['editDataBatch'];
        }

        if (requestParameters['keyIntegration'] != null) {
            queryParameters['keyIntegration'] = requestParameters['keyIntegration'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xHarvest'] != null) {
            headerParameters['x-harvest'] = String(requestParameters['xHarvest']);
        }

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/invoices/devolutions/notes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async devolutionsInvoicesNotesDataControllerFindAll(requestParameters: DevolutionsInvoicesNotesDataControllerFindAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginationResponseDto> {
        const response = await this.devolutionsInvoicesNotesDataControllerFindAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async devolutionsInvoicesNotesDataControllerFindAllWithoutInvoicesRaw(requestParameters: DevolutionsInvoicesNotesDataControllerFindAllWithoutInvoicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginationResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['dataMultiplierIdentifierNumber'] != null) {
            queryParameters['dataMultiplierIdentifierNumber'] = requestParameters['dataMultiplierIdentifierNumber'];
        }

        if (requestParameters['dataNfe'] != null) {
            queryParameters['dataNfe'] = requestParameters['dataNfe'];
        }

        if (requestParameters['dataIdentifierNumber'] != null) {
            queryParameters['dataIdentifierNumber'] = requestParameters['dataIdentifierNumber'];
        }

        if (requestParameters['dataDevolutionNfe'] != null) {
            queryParameters['dataDevolutionNfe'] = requestParameters['dataDevolutionNfe'];
        }

        if (requestParameters['dataCustomerName'] != null) {
            queryParameters['dataCustomerName'] = requestParameters['dataCustomerName'];
        }

        if (requestParameters['dataBatch'] != null) {
            queryParameters['dataBatch'] = requestParameters['dataBatch'];
        }

        if (requestParameters['dataWeightKq'] != null) {
            queryParameters['dataWeightKq'] = requestParameters['dataWeightKq'];
        }

        if (requestParameters['editDataMultiplierIdentifierNumber'] != null) {
            queryParameters['editDataMultiplierIdentifierNumber'] = requestParameters['editDataMultiplierIdentifierNumber'];
        }

        if (requestParameters['editDataNfe'] != null) {
            queryParameters['editDataNfe'] = requestParameters['editDataNfe'];
        }

        if (requestParameters['editDataIdentifierNumber'] != null) {
            queryParameters['editDataIdentifierNumber'] = requestParameters['editDataIdentifierNumber'];
        }

        if (requestParameters['editDataWeightKq'] != null) {
            queryParameters['editDataWeightKq'] = requestParameters['editDataWeightKq'];
        }

        if (requestParameters['editDataDevolutionNfe'] != null) {
            queryParameters['editDataDevolutionNfe'] = requestParameters['editDataDevolutionNfe'];
        }

        if (requestParameters['editDataCustomerName'] != null) {
            queryParameters['editDataCustomerName'] = requestParameters['editDataCustomerName'];
        }

        if (requestParameters['editDataBatch'] != null) {
            queryParameters['editDataBatch'] = requestParameters['editDataBatch'];
        }

        if (requestParameters['keyIntegration'] != null) {
            queryParameters['keyIntegration'] = requestParameters['keyIntegration'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xHarvest'] != null) {
            headerParameters['x-harvest'] = String(requestParameters['xHarvest']);
        }

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/invoices/devolutions/notes/withoutInvoices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async devolutionsInvoicesNotesDataControllerFindAllWithoutInvoices(requestParameters: DevolutionsInvoicesNotesDataControllerFindAllWithoutInvoicesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginationResponseDto> {
        const response = await this.devolutionsInvoicesNotesDataControllerFindAllWithoutInvoicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async devolutionsInvoicesNotesDataControllerGetTotalsRaw(requestParameters: DevolutionsInvoicesNotesDataControllerGetTotalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DevolutionsInvoicesNotesTotalsDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['dataMultiplierIdentifierNumber'] != null) {
            queryParameters['dataMultiplierIdentifierNumber'] = requestParameters['dataMultiplierIdentifierNumber'];
        }

        if (requestParameters['dataNfe'] != null) {
            queryParameters['dataNfe'] = requestParameters['dataNfe'];
        }

        if (requestParameters['dataIdentifierNumber'] != null) {
            queryParameters['dataIdentifierNumber'] = requestParameters['dataIdentifierNumber'];
        }

        if (requestParameters['dataDevolutionNfe'] != null) {
            queryParameters['dataDevolutionNfe'] = requestParameters['dataDevolutionNfe'];
        }

        if (requestParameters['dataCustomerName'] != null) {
            queryParameters['dataCustomerName'] = requestParameters['dataCustomerName'];
        }

        if (requestParameters['dataBatch'] != null) {
            queryParameters['dataBatch'] = requestParameters['dataBatch'];
        }

        if (requestParameters['dataWeightKq'] != null) {
            queryParameters['dataWeightKq'] = requestParameters['dataWeightKq'];
        }

        if (requestParameters['editDataMultiplierIdentifierNumber'] != null) {
            queryParameters['editDataMultiplierIdentifierNumber'] = requestParameters['editDataMultiplierIdentifierNumber'];
        }

        if (requestParameters['editDataNfe'] != null) {
            queryParameters['editDataNfe'] = requestParameters['editDataNfe'];
        }

        if (requestParameters['editDataIdentifierNumber'] != null) {
            queryParameters['editDataIdentifierNumber'] = requestParameters['editDataIdentifierNumber'];
        }

        if (requestParameters['editDataWeightKq'] != null) {
            queryParameters['editDataWeightKq'] = requestParameters['editDataWeightKq'];
        }

        if (requestParameters['editDataDevolutionNfe'] != null) {
            queryParameters['editDataDevolutionNfe'] = requestParameters['editDataDevolutionNfe'];
        }

        if (requestParameters['editDataCustomerName'] != null) {
            queryParameters['editDataCustomerName'] = requestParameters['editDataCustomerName'];
        }

        if (requestParameters['editDataBatch'] != null) {
            queryParameters['editDataBatch'] = requestParameters['editDataBatch'];
        }

        if (requestParameters['keyIntegration'] != null) {
            queryParameters['keyIntegration'] = requestParameters['keyIntegration'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xHarvest'] != null) {
            headerParameters['x-harvest'] = String(requestParameters['xHarvest']);
        }

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/invoices/devolutions/totals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DevolutionsInvoicesNotesTotalsDtoFromJSON(jsonValue));
    }

    /**
     */
    async devolutionsInvoicesNotesDataControllerGetTotals(requestParameters: DevolutionsInvoicesNotesDataControllerGetTotalsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DevolutionsInvoicesNotesTotalsDto> {
        const response = await this.devolutionsInvoicesNotesDataControllerGetTotalsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async devolutionsInvoicesNotesDataControllerUpdateRaw(requestParameters: DevolutionsInvoicesNotesDataControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateResponseDto>> {
        if (requestParameters['updateDevolutionsInvoicesNotesDataDto'] == null) {
            throw new runtime.RequiredError(
                'updateDevolutionsInvoicesNotesDataDto',
                'Required parameter "updateDevolutionsInvoicesNotesDataDto" was null or undefined when calling devolutionsInvoicesNotesDataControllerUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['dataMultiplierIdentifierNumber'] != null) {
            queryParameters['dataMultiplierIdentifierNumber'] = requestParameters['dataMultiplierIdentifierNumber'];
        }

        if (requestParameters['dataNfe'] != null) {
            queryParameters['dataNfe'] = requestParameters['dataNfe'];
        }

        if (requestParameters['dataIdentifierNumber'] != null) {
            queryParameters['dataIdentifierNumber'] = requestParameters['dataIdentifierNumber'];
        }

        if (requestParameters['dataDevolutionNfe'] != null) {
            queryParameters['dataDevolutionNfe'] = requestParameters['dataDevolutionNfe'];
        }

        if (requestParameters['dataCustomerName'] != null) {
            queryParameters['dataCustomerName'] = requestParameters['dataCustomerName'];
        }

        if (requestParameters['dataBatch'] != null) {
            queryParameters['dataBatch'] = requestParameters['dataBatch'];
        }

        if (requestParameters['dataWeightKq'] != null) {
            queryParameters['dataWeightKq'] = requestParameters['dataWeightKq'];
        }

        if (requestParameters['editDataMultiplierIdentifierNumber'] != null) {
            queryParameters['editDataMultiplierIdentifierNumber'] = requestParameters['editDataMultiplierIdentifierNumber'];
        }

        if (requestParameters['editDataNfe'] != null) {
            queryParameters['editDataNfe'] = requestParameters['editDataNfe'];
        }

        if (requestParameters['editDataIdentifierNumber'] != null) {
            queryParameters['editDataIdentifierNumber'] = requestParameters['editDataIdentifierNumber'];
        }

        if (requestParameters['editDataWeightKq'] != null) {
            queryParameters['editDataWeightKq'] = requestParameters['editDataWeightKq'];
        }

        if (requestParameters['editDataDevolutionNfe'] != null) {
            queryParameters['editDataDevolutionNfe'] = requestParameters['editDataDevolutionNfe'];
        }

        if (requestParameters['editDataCustomerName'] != null) {
            queryParameters['editDataCustomerName'] = requestParameters['editDataCustomerName'];
        }

        if (requestParameters['editDataBatch'] != null) {
            queryParameters['editDataBatch'] = requestParameters['editDataBatch'];
        }

        if (requestParameters['keyIntegration'] != null) {
            queryParameters['keyIntegration'] = requestParameters['keyIntegration'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['xHarvest'] != null) {
            headerParameters['x-harvest'] = String(requestParameters['xHarvest']);
        }

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/invoices/devolutions/notes`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDevolutionsInvoicesNotesDataDtoToJSON(requestParameters['updateDevolutionsInvoicesNotesDataDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async devolutionsInvoicesNotesDataControllerUpdate(requestParameters: DevolutionsInvoicesNotesDataControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateResponseDto> {
        const response = await this.devolutionsInvoicesNotesDataControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
