import { Tag } from "antd";
import { InvoiceStatusDef } from "./types";

interface Props {
  value: string;
}

const TagInvoiceStatus = (props: Props) => {
  const { label, color, icon } =
    InvoiceStatusDef[props?.value] || InvoiceStatusDef.not_reported;
  return (
    <Tag color={color} icon={icon}>
      {label}
    </Tag>
  );
};

export default TagInvoiceStatus;
