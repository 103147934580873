/* tslint:disable */
/* eslint-disable */
/**
 * Agro Royalties
 * The Sou Agro Royalties API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BgProcessDto,
  InvoicesNotesTotalsDto,
  PaginationResponseDto,
  UpdateInvoicesNotesDataDto,
  UpdateResponseDto,
} from '../models/index';
import {
    BgProcessDtoFromJSON,
    BgProcessDtoToJSON,
    InvoicesNotesTotalsDtoFromJSON,
    InvoicesNotesTotalsDtoToJSON,
    PaginationResponseDtoFromJSON,
    PaginationResponseDtoToJSON,
    UpdateInvoicesNotesDataDtoFromJSON,
    UpdateInvoicesNotesDataDtoToJSON,
    UpdateResponseDtoFromJSON,
    UpdateResponseDtoToJSON,
} from '../models/index';

export interface InvoicesNotesDataControllerExportAllRequest {
    xHarvest?: string;
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    id?: number;
    status?: Array<string>;
    statusCategory?: Array<string>;
    dataMultiplierIdentifierNumber?: string;
    dataNfe?: string;
    dataIdentifierNumber?: string;
    dataUf?: string;
    dataCity?: string;
    dataBillingDate?: string;
    dataSeedUse?: string;
    dataCultivate?: string;
    dataQtyPackaging?: string;
    dataClientName?: string;
    dataBatch?: string;
    dataTechnology?: string;
    dataWeightPackaging?: string;
    dataWeightKq?: string;
    dataCfop?: string;
    editDataMultiplierIdentifierNumber?: string;
    editDataNfe?: string;
    editDataIdentifierNumber?: string;
    editDataUf?: string;
    editDataCity?: string;
    editDataBillingDate?: string;
    editDataSeedUse?: string;
    editDataCultivate?: string;
    editDataQtyPackaging?: string;
    editDataClientName?: string;
    editDataBatch?: string;
    editDataTechnology?: string;
    editDataWeightPackaging?: string;
    editDataWeightKq?: string;
    editDataCfop?: string;
    revision?: boolean;
    keyIntegration?: string;
}

export interface InvoicesNotesDataControllerExportAllUnprocessedRequest {
    xHarvest?: string;
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    id?: number;
    status?: string;
    statusCategory?: string;
    nfe?: string;
    billingDate?: string;
    type?: string;
    multiplierTypeIdentifierDoc?: string;
    multiplierIdentifierNumber?: string;
    typeIdentifierDoc?: string;
    identifierNumber?: string;
    seedUse?: string;
    uf?: string;
    city?: string;
    batch?: string;
    product?: string;
    weightKq?: string;
    saleType?: string;
}

export interface InvoicesNotesDataControllerExportAllWithoutQuotasRequest {
    xHarvest?: string;
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    id?: number;
    status?: Array<string>;
    statusCategory?: Array<string>;
    dataMultiplierIdentifierNumber?: string;
    dataNfe?: string;
    dataIdentifierNumber?: string;
    dataUf?: string;
    dataCity?: string;
    dataBillingDate?: string;
    dataSeedUse?: string;
    dataCultivate?: string;
    dataQtyPackaging?: string;
    dataClientName?: string;
    dataBatch?: string;
    dataTechnology?: string;
    dataWeightPackaging?: string;
    dataWeightKq?: string;
    dataCfop?: string;
    editDataMultiplierIdentifierNumber?: string;
    editDataNfe?: string;
    editDataIdentifierNumber?: string;
    editDataUf?: string;
    editDataCity?: string;
    editDataBillingDate?: string;
    editDataSeedUse?: string;
    editDataCultivate?: string;
    editDataQtyPackaging?: string;
    editDataClientName?: string;
    editDataBatch?: string;
    editDataTechnology?: string;
    editDataWeightPackaging?: string;
    editDataWeightKq?: string;
    editDataCfop?: string;
    revision?: boolean;
    keyIntegration?: string;
}

export interface InvoicesNotesDataControllerFindAllRequest {
    xHarvest?: string;
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    id?: number;
    status?: Array<string>;
    statusCategory?: Array<string>;
    dataMultiplierIdentifierNumber?: string;
    dataNfe?: string;
    dataIdentifierNumber?: string;
    dataUf?: string;
    dataCity?: string;
    dataBillingDate?: string;
    dataSeedUse?: string;
    dataCultivate?: string;
    dataQtyPackaging?: string;
    dataClientName?: string;
    dataBatch?: string;
    dataTechnology?: string;
    dataWeightPackaging?: string;
    dataWeightKq?: string;
    dataCfop?: string;
    editDataMultiplierIdentifierNumber?: string;
    editDataNfe?: string;
    editDataIdentifierNumber?: string;
    editDataUf?: string;
    editDataCity?: string;
    editDataBillingDate?: string;
    editDataSeedUse?: string;
    editDataCultivate?: string;
    editDataQtyPackaging?: string;
    editDataClientName?: string;
    editDataBatch?: string;
    editDataTechnology?: string;
    editDataWeightPackaging?: string;
    editDataWeightKq?: string;
    editDataCfop?: string;
    revision?: boolean;
    keyIntegration?: string;
}

export interface InvoicesNotesDataControllerFindAllUnprocessedRequest {
    xHarvest?: string;
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    id?: number;
    status?: string;
    statusCategory?: string;
    nfe?: string;
    billingDate?: string;
    type?: string;
    multiplierTypeIdentifierDoc?: string;
    multiplierIdentifierNumber?: string;
    typeIdentifierDoc?: string;
    identifierNumber?: string;
    seedUse?: string;
    uf?: string;
    city?: string;
    batch?: string;
    product?: string;
    weightKq?: string;
    saleType?: string;
}

export interface InvoicesNotesDataControllerFindAllWithoutQuotasRequest {
    xHarvest?: string;
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    id?: number;
    status?: Array<string>;
    statusCategory?: Array<string>;
    dataMultiplierIdentifierNumber?: string;
    dataNfe?: string;
    dataIdentifierNumber?: string;
    dataUf?: string;
    dataCity?: string;
    dataBillingDate?: string;
    dataSeedUse?: string;
    dataCultivate?: string;
    dataQtyPackaging?: string;
    dataClientName?: string;
    dataBatch?: string;
    dataTechnology?: string;
    dataWeightPackaging?: string;
    dataWeightKq?: string;
    dataCfop?: string;
    editDataMultiplierIdentifierNumber?: string;
    editDataNfe?: string;
    editDataIdentifierNumber?: string;
    editDataUf?: string;
    editDataCity?: string;
    editDataBillingDate?: string;
    editDataSeedUse?: string;
    editDataCultivate?: string;
    editDataQtyPackaging?: string;
    editDataClientName?: string;
    editDataBatch?: string;
    editDataTechnology?: string;
    editDataWeightPackaging?: string;
    editDataWeightKq?: string;
    editDataCfop?: string;
    revision?: boolean;
    keyIntegration?: string;
}

export interface InvoicesNotesDataControllerGetRevissionsRequest {
    key: string;
    xHarvest?: string;
    xOwnerId?: string;
}

export interface InvoicesNotesDataControllerGetTotalsRequest {
    xHarvest?: string;
    xOwnerId?: string;
    pageSize?: number;
    page?: number;
    id?: number;
    status?: Array<string>;
    statusCategory?: Array<string>;
    dataMultiplierIdentifierNumber?: string;
    dataNfe?: string;
    dataIdentifierNumber?: string;
    dataUf?: string;
    dataCity?: string;
    dataBillingDate?: string;
    dataSeedUse?: string;
    dataCultivate?: string;
    dataQtyPackaging?: string;
    dataClientName?: string;
    dataBatch?: string;
    dataTechnology?: string;
    dataWeightPackaging?: string;
    dataWeightKq?: string;
    dataCfop?: string;
    editDataMultiplierIdentifierNumber?: string;
    editDataNfe?: string;
    editDataIdentifierNumber?: string;
    editDataUf?: string;
    editDataCity?: string;
    editDataBillingDate?: string;
    editDataSeedUse?: string;
    editDataCultivate?: string;
    editDataQtyPackaging?: string;
    editDataClientName?: string;
    editDataBatch?: string;
    editDataTechnology?: string;
    editDataWeightPackaging?: string;
    editDataWeightKq?: string;
    editDataCfop?: string;
    revision?: boolean;
    keyIntegration?: string;
}

export interface InvoicesNotesDataControllerSoftDeleteRequest {
    xHarvest?: string;
    xOwnerId?: string;
    id?: number;
    status?: Array<string>;
    statusCategory?: Array<string>;
    dataMultiplierIdentifierNumber?: string;
    dataNfe?: string;
    dataIdentifierNumber?: string;
    dataUf?: string;
    dataCity?: string;
    dataBillingDate?: string;
    dataSeedUse?: string;
    dataCultivate?: string;
    dataQtyPackaging?: string;
    dataClientName?: string;
    dataBatch?: string;
    dataTechnology?: string;
    dataWeightPackaging?: string;
    dataWeightKq?: string;
    dataCfop?: string;
    editDataMultiplierIdentifierNumber?: string;
    editDataNfe?: string;
    editDataIdentifierNumber?: string;
    editDataUf?: string;
    editDataCity?: string;
    editDataBillingDate?: string;
    editDataSeedUse?: string;
    editDataCultivate?: string;
    editDataQtyPackaging?: string;
    editDataClientName?: string;
    editDataBatch?: string;
    editDataTechnology?: string;
    editDataWeightPackaging?: string;
    editDataWeightKq?: string;
    editDataCfop?: string;
    revision?: boolean;
    keyIntegration?: string;
}

export interface InvoicesNotesDataControllerUpdateRequest {
    updateInvoicesNotesDataDto: UpdateInvoicesNotesDataDto;
    xHarvest?: string;
    xOwnerId?: string;
    id?: number;
    status?: Array<string>;
    statusCategory?: Array<string>;
    dataMultiplierIdentifierNumber?: string;
    dataNfe?: string;
    dataIdentifierNumber?: string;
    dataUf?: string;
    dataCity?: string;
    dataBillingDate?: string;
    dataSeedUse?: string;
    dataCultivate?: string;
    dataQtyPackaging?: string;
    dataClientName?: string;
    dataBatch?: string;
    dataTechnology?: string;
    dataWeightPackaging?: string;
    dataWeightKq?: string;
    dataCfop?: string;
    editDataMultiplierIdentifierNumber?: string;
    editDataNfe?: string;
    editDataIdentifierNumber?: string;
    editDataUf?: string;
    editDataCity?: string;
    editDataBillingDate?: string;
    editDataSeedUse?: string;
    editDataCultivate?: string;
    editDataQtyPackaging?: string;
    editDataClientName?: string;
    editDataBatch?: string;
    editDataTechnology?: string;
    editDataWeightPackaging?: string;
    editDataWeightKq?: string;
    editDataCfop?: string;
    revision?: boolean;
    keyIntegration?: string;
}

/**
 * 
 */
export class InvoicesNotesDataApi extends runtime.BaseAPI {

    /**
     */
    async invoicesNotesDataControllerExportAllRaw(requestParameters: InvoicesNotesDataControllerExportAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BgProcessDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['statusCategory'] != null) {
            queryParameters['statusCategory'] = requestParameters['statusCategory'];
        }

        if (requestParameters['dataMultiplierIdentifierNumber'] != null) {
            queryParameters['dataMultiplierIdentifierNumber'] = requestParameters['dataMultiplierIdentifierNumber'];
        }

        if (requestParameters['dataNfe'] != null) {
            queryParameters['dataNfe'] = requestParameters['dataNfe'];
        }

        if (requestParameters['dataIdentifierNumber'] != null) {
            queryParameters['dataIdentifierNumber'] = requestParameters['dataIdentifierNumber'];
        }

        if (requestParameters['dataUf'] != null) {
            queryParameters['dataUf'] = requestParameters['dataUf'];
        }

        if (requestParameters['dataCity'] != null) {
            queryParameters['dataCity'] = requestParameters['dataCity'];
        }

        if (requestParameters['dataBillingDate'] != null) {
            queryParameters['dataBillingDate'] = requestParameters['dataBillingDate'];
        }

        if (requestParameters['dataSeedUse'] != null) {
            queryParameters['dataSeedUse'] = requestParameters['dataSeedUse'];
        }

        if (requestParameters['dataCultivate'] != null) {
            queryParameters['dataCultivate'] = requestParameters['dataCultivate'];
        }

        if (requestParameters['dataQtyPackaging'] != null) {
            queryParameters['dataQtyPackaging'] = requestParameters['dataQtyPackaging'];
        }

        if (requestParameters['dataClientName'] != null) {
            queryParameters['dataClientName'] = requestParameters['dataClientName'];
        }

        if (requestParameters['dataBatch'] != null) {
            queryParameters['dataBatch'] = requestParameters['dataBatch'];
        }

        if (requestParameters['dataTechnology'] != null) {
            queryParameters['dataTechnology'] = requestParameters['dataTechnology'];
        }

        if (requestParameters['dataWeightPackaging'] != null) {
            queryParameters['dataWeightPackaging'] = requestParameters['dataWeightPackaging'];
        }

        if (requestParameters['dataWeightKq'] != null) {
            queryParameters['dataWeightKq'] = requestParameters['dataWeightKq'];
        }

        if (requestParameters['dataCfop'] != null) {
            queryParameters['dataCfop'] = requestParameters['dataCfop'];
        }

        if (requestParameters['editDataMultiplierIdentifierNumber'] != null) {
            queryParameters['editDataMultiplierIdentifierNumber'] = requestParameters['editDataMultiplierIdentifierNumber'];
        }

        if (requestParameters['editDataNfe'] != null) {
            queryParameters['editDataNfe'] = requestParameters['editDataNfe'];
        }

        if (requestParameters['editDataIdentifierNumber'] != null) {
            queryParameters['editDataIdentifierNumber'] = requestParameters['editDataIdentifierNumber'];
        }

        if (requestParameters['editDataUf'] != null) {
            queryParameters['editDataUf'] = requestParameters['editDataUf'];
        }

        if (requestParameters['editDataCity'] != null) {
            queryParameters['editDataCity'] = requestParameters['editDataCity'];
        }

        if (requestParameters['editDataBillingDate'] != null) {
            queryParameters['editDataBillingDate'] = requestParameters['editDataBillingDate'];
        }

        if (requestParameters['editDataSeedUse'] != null) {
            queryParameters['editDataSeedUse'] = requestParameters['editDataSeedUse'];
        }

        if (requestParameters['editDataCultivate'] != null) {
            queryParameters['editDataCultivate'] = requestParameters['editDataCultivate'];
        }

        if (requestParameters['editDataQtyPackaging'] != null) {
            queryParameters['editDataQtyPackaging'] = requestParameters['editDataQtyPackaging'];
        }

        if (requestParameters['editDataClientName'] != null) {
            queryParameters['editDataClientName'] = requestParameters['editDataClientName'];
        }

        if (requestParameters['editDataBatch'] != null) {
            queryParameters['editDataBatch'] = requestParameters['editDataBatch'];
        }

        if (requestParameters['editDataTechnology'] != null) {
            queryParameters['editDataTechnology'] = requestParameters['editDataTechnology'];
        }

        if (requestParameters['editDataWeightPackaging'] != null) {
            queryParameters['editDataWeightPackaging'] = requestParameters['editDataWeightPackaging'];
        }

        if (requestParameters['editDataWeightKq'] != null) {
            queryParameters['editDataWeightKq'] = requestParameters['editDataWeightKq'];
        }

        if (requestParameters['editDataCfop'] != null) {
            queryParameters['editDataCfop'] = requestParameters['editDataCfop'];
        }

        if (requestParameters['revision'] != null) {
            queryParameters['revision'] = requestParameters['revision'];
        }

        if (requestParameters['keyIntegration'] != null) {
            queryParameters['keyIntegration'] = requestParameters['keyIntegration'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xHarvest'] != null) {
            headerParameters['x-harvest'] = String(requestParameters['xHarvest']);
        }

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/invoices/notes/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BgProcessDtoFromJSON(jsonValue));
    }

    /**
     */
    async invoicesNotesDataControllerExportAll(requestParameters: InvoicesNotesDataControllerExportAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BgProcessDto> {
        const response = await this.invoicesNotesDataControllerExportAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async invoicesNotesDataControllerExportAllUnprocessedRaw(requestParameters: InvoicesNotesDataControllerExportAllUnprocessedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BgProcessDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['statusCategory'] != null) {
            queryParameters['statusCategory'] = requestParameters['statusCategory'];
        }

        if (requestParameters['nfe'] != null) {
            queryParameters['nfe'] = requestParameters['nfe'];
        }

        if (requestParameters['billingDate'] != null) {
            queryParameters['billingDate'] = requestParameters['billingDate'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        if (requestParameters['multiplierTypeIdentifierDoc'] != null) {
            queryParameters['multiplierTypeIdentifierDoc'] = requestParameters['multiplierTypeIdentifierDoc'];
        }

        if (requestParameters['multiplierIdentifierNumber'] != null) {
            queryParameters['multiplierIdentifierNumber'] = requestParameters['multiplierIdentifierNumber'];
        }

        if (requestParameters['typeIdentifierDoc'] != null) {
            queryParameters['typeIdentifierDoc'] = requestParameters['typeIdentifierDoc'];
        }

        if (requestParameters['identifierNumber'] != null) {
            queryParameters['identifierNumber'] = requestParameters['identifierNumber'];
        }

        if (requestParameters['seedUse'] != null) {
            queryParameters['seedUse'] = requestParameters['seedUse'];
        }

        if (requestParameters['uf'] != null) {
            queryParameters['uf'] = requestParameters['uf'];
        }

        if (requestParameters['city'] != null) {
            queryParameters['city'] = requestParameters['city'];
        }

        if (requestParameters['batch'] != null) {
            queryParameters['batch'] = requestParameters['batch'];
        }

        if (requestParameters['product'] != null) {
            queryParameters['Product'] = requestParameters['product'];
        }

        if (requestParameters['weightKq'] != null) {
            queryParameters['weightKq'] = requestParameters['weightKq'];
        }

        if (requestParameters['saleType'] != null) {
            queryParameters['saleType'] = requestParameters['saleType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xHarvest'] != null) {
            headerParameters['x-harvest'] = String(requestParameters['xHarvest']);
        }

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/invoices/notes/unprocessed/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BgProcessDtoFromJSON(jsonValue));
    }

    /**
     */
    async invoicesNotesDataControllerExportAllUnprocessed(requestParameters: InvoicesNotesDataControllerExportAllUnprocessedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BgProcessDto> {
        const response = await this.invoicesNotesDataControllerExportAllUnprocessedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async invoicesNotesDataControllerExportAllWithoutQuotasRaw(requestParameters: InvoicesNotesDataControllerExportAllWithoutQuotasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BgProcessDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['statusCategory'] != null) {
            queryParameters['statusCategory'] = requestParameters['statusCategory'];
        }

        if (requestParameters['dataMultiplierIdentifierNumber'] != null) {
            queryParameters['dataMultiplierIdentifierNumber'] = requestParameters['dataMultiplierIdentifierNumber'];
        }

        if (requestParameters['dataNfe'] != null) {
            queryParameters['dataNfe'] = requestParameters['dataNfe'];
        }

        if (requestParameters['dataIdentifierNumber'] != null) {
            queryParameters['dataIdentifierNumber'] = requestParameters['dataIdentifierNumber'];
        }

        if (requestParameters['dataUf'] != null) {
            queryParameters['dataUf'] = requestParameters['dataUf'];
        }

        if (requestParameters['dataCity'] != null) {
            queryParameters['dataCity'] = requestParameters['dataCity'];
        }

        if (requestParameters['dataBillingDate'] != null) {
            queryParameters['dataBillingDate'] = requestParameters['dataBillingDate'];
        }

        if (requestParameters['dataSeedUse'] != null) {
            queryParameters['dataSeedUse'] = requestParameters['dataSeedUse'];
        }

        if (requestParameters['dataCultivate'] != null) {
            queryParameters['dataCultivate'] = requestParameters['dataCultivate'];
        }

        if (requestParameters['dataQtyPackaging'] != null) {
            queryParameters['dataQtyPackaging'] = requestParameters['dataQtyPackaging'];
        }

        if (requestParameters['dataClientName'] != null) {
            queryParameters['dataClientName'] = requestParameters['dataClientName'];
        }

        if (requestParameters['dataBatch'] != null) {
            queryParameters['dataBatch'] = requestParameters['dataBatch'];
        }

        if (requestParameters['dataTechnology'] != null) {
            queryParameters['dataTechnology'] = requestParameters['dataTechnology'];
        }

        if (requestParameters['dataWeightPackaging'] != null) {
            queryParameters['dataWeightPackaging'] = requestParameters['dataWeightPackaging'];
        }

        if (requestParameters['dataWeightKq'] != null) {
            queryParameters['dataWeightKq'] = requestParameters['dataWeightKq'];
        }

        if (requestParameters['dataCfop'] != null) {
            queryParameters['dataCfop'] = requestParameters['dataCfop'];
        }

        if (requestParameters['editDataMultiplierIdentifierNumber'] != null) {
            queryParameters['editDataMultiplierIdentifierNumber'] = requestParameters['editDataMultiplierIdentifierNumber'];
        }

        if (requestParameters['editDataNfe'] != null) {
            queryParameters['editDataNfe'] = requestParameters['editDataNfe'];
        }

        if (requestParameters['editDataIdentifierNumber'] != null) {
            queryParameters['editDataIdentifierNumber'] = requestParameters['editDataIdentifierNumber'];
        }

        if (requestParameters['editDataUf'] != null) {
            queryParameters['editDataUf'] = requestParameters['editDataUf'];
        }

        if (requestParameters['editDataCity'] != null) {
            queryParameters['editDataCity'] = requestParameters['editDataCity'];
        }

        if (requestParameters['editDataBillingDate'] != null) {
            queryParameters['editDataBillingDate'] = requestParameters['editDataBillingDate'];
        }

        if (requestParameters['editDataSeedUse'] != null) {
            queryParameters['editDataSeedUse'] = requestParameters['editDataSeedUse'];
        }

        if (requestParameters['editDataCultivate'] != null) {
            queryParameters['editDataCultivate'] = requestParameters['editDataCultivate'];
        }

        if (requestParameters['editDataQtyPackaging'] != null) {
            queryParameters['editDataQtyPackaging'] = requestParameters['editDataQtyPackaging'];
        }

        if (requestParameters['editDataClientName'] != null) {
            queryParameters['editDataClientName'] = requestParameters['editDataClientName'];
        }

        if (requestParameters['editDataBatch'] != null) {
            queryParameters['editDataBatch'] = requestParameters['editDataBatch'];
        }

        if (requestParameters['editDataTechnology'] != null) {
            queryParameters['editDataTechnology'] = requestParameters['editDataTechnology'];
        }

        if (requestParameters['editDataWeightPackaging'] != null) {
            queryParameters['editDataWeightPackaging'] = requestParameters['editDataWeightPackaging'];
        }

        if (requestParameters['editDataWeightKq'] != null) {
            queryParameters['editDataWeightKq'] = requestParameters['editDataWeightKq'];
        }

        if (requestParameters['editDataCfop'] != null) {
            queryParameters['editDataCfop'] = requestParameters['editDataCfop'];
        }

        if (requestParameters['revision'] != null) {
            queryParameters['revision'] = requestParameters['revision'];
        }

        if (requestParameters['keyIntegration'] != null) {
            queryParameters['keyIntegration'] = requestParameters['keyIntegration'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xHarvest'] != null) {
            headerParameters['x-harvest'] = String(requestParameters['xHarvest']);
        }

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/invoices/notes/withoutquotas/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BgProcessDtoFromJSON(jsonValue));
    }

    /**
     */
    async invoicesNotesDataControllerExportAllWithoutQuotas(requestParameters: InvoicesNotesDataControllerExportAllWithoutQuotasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BgProcessDto> {
        const response = await this.invoicesNotesDataControllerExportAllWithoutQuotasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async invoicesNotesDataControllerFindAllRaw(requestParameters: InvoicesNotesDataControllerFindAllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginationResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['statusCategory'] != null) {
            queryParameters['statusCategory'] = requestParameters['statusCategory'];
        }

        if (requestParameters['dataMultiplierIdentifierNumber'] != null) {
            queryParameters['dataMultiplierIdentifierNumber'] = requestParameters['dataMultiplierIdentifierNumber'];
        }

        if (requestParameters['dataNfe'] != null) {
            queryParameters['dataNfe'] = requestParameters['dataNfe'];
        }

        if (requestParameters['dataIdentifierNumber'] != null) {
            queryParameters['dataIdentifierNumber'] = requestParameters['dataIdentifierNumber'];
        }

        if (requestParameters['dataUf'] != null) {
            queryParameters['dataUf'] = requestParameters['dataUf'];
        }

        if (requestParameters['dataCity'] != null) {
            queryParameters['dataCity'] = requestParameters['dataCity'];
        }

        if (requestParameters['dataBillingDate'] != null) {
            queryParameters['dataBillingDate'] = requestParameters['dataBillingDate'];
        }

        if (requestParameters['dataSeedUse'] != null) {
            queryParameters['dataSeedUse'] = requestParameters['dataSeedUse'];
        }

        if (requestParameters['dataCultivate'] != null) {
            queryParameters['dataCultivate'] = requestParameters['dataCultivate'];
        }

        if (requestParameters['dataQtyPackaging'] != null) {
            queryParameters['dataQtyPackaging'] = requestParameters['dataQtyPackaging'];
        }

        if (requestParameters['dataClientName'] != null) {
            queryParameters['dataClientName'] = requestParameters['dataClientName'];
        }

        if (requestParameters['dataBatch'] != null) {
            queryParameters['dataBatch'] = requestParameters['dataBatch'];
        }

        if (requestParameters['dataTechnology'] != null) {
            queryParameters['dataTechnology'] = requestParameters['dataTechnology'];
        }

        if (requestParameters['dataWeightPackaging'] != null) {
            queryParameters['dataWeightPackaging'] = requestParameters['dataWeightPackaging'];
        }

        if (requestParameters['dataWeightKq'] != null) {
            queryParameters['dataWeightKq'] = requestParameters['dataWeightKq'];
        }

        if (requestParameters['dataCfop'] != null) {
            queryParameters['dataCfop'] = requestParameters['dataCfop'];
        }

        if (requestParameters['editDataMultiplierIdentifierNumber'] != null) {
            queryParameters['editDataMultiplierIdentifierNumber'] = requestParameters['editDataMultiplierIdentifierNumber'];
        }

        if (requestParameters['editDataNfe'] != null) {
            queryParameters['editDataNfe'] = requestParameters['editDataNfe'];
        }

        if (requestParameters['editDataIdentifierNumber'] != null) {
            queryParameters['editDataIdentifierNumber'] = requestParameters['editDataIdentifierNumber'];
        }

        if (requestParameters['editDataUf'] != null) {
            queryParameters['editDataUf'] = requestParameters['editDataUf'];
        }

        if (requestParameters['editDataCity'] != null) {
            queryParameters['editDataCity'] = requestParameters['editDataCity'];
        }

        if (requestParameters['editDataBillingDate'] != null) {
            queryParameters['editDataBillingDate'] = requestParameters['editDataBillingDate'];
        }

        if (requestParameters['editDataSeedUse'] != null) {
            queryParameters['editDataSeedUse'] = requestParameters['editDataSeedUse'];
        }

        if (requestParameters['editDataCultivate'] != null) {
            queryParameters['editDataCultivate'] = requestParameters['editDataCultivate'];
        }

        if (requestParameters['editDataQtyPackaging'] != null) {
            queryParameters['editDataQtyPackaging'] = requestParameters['editDataQtyPackaging'];
        }

        if (requestParameters['editDataClientName'] != null) {
            queryParameters['editDataClientName'] = requestParameters['editDataClientName'];
        }

        if (requestParameters['editDataBatch'] != null) {
            queryParameters['editDataBatch'] = requestParameters['editDataBatch'];
        }

        if (requestParameters['editDataTechnology'] != null) {
            queryParameters['editDataTechnology'] = requestParameters['editDataTechnology'];
        }

        if (requestParameters['editDataWeightPackaging'] != null) {
            queryParameters['editDataWeightPackaging'] = requestParameters['editDataWeightPackaging'];
        }

        if (requestParameters['editDataWeightKq'] != null) {
            queryParameters['editDataWeightKq'] = requestParameters['editDataWeightKq'];
        }

        if (requestParameters['editDataCfop'] != null) {
            queryParameters['editDataCfop'] = requestParameters['editDataCfop'];
        }

        if (requestParameters['revision'] != null) {
            queryParameters['revision'] = requestParameters['revision'];
        }

        if (requestParameters['keyIntegration'] != null) {
            queryParameters['keyIntegration'] = requestParameters['keyIntegration'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xHarvest'] != null) {
            headerParameters['x-harvest'] = String(requestParameters['xHarvest']);
        }

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/invoices/notes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async invoicesNotesDataControllerFindAll(requestParameters: InvoicesNotesDataControllerFindAllRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginationResponseDto> {
        const response = await this.invoicesNotesDataControllerFindAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async invoicesNotesDataControllerFindAllUnprocessedRaw(requestParameters: InvoicesNotesDataControllerFindAllUnprocessedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginationResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['statusCategory'] != null) {
            queryParameters['statusCategory'] = requestParameters['statusCategory'];
        }

        if (requestParameters['nfe'] != null) {
            queryParameters['nfe'] = requestParameters['nfe'];
        }

        if (requestParameters['billingDate'] != null) {
            queryParameters['billingDate'] = requestParameters['billingDate'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        if (requestParameters['multiplierTypeIdentifierDoc'] != null) {
            queryParameters['multiplierTypeIdentifierDoc'] = requestParameters['multiplierTypeIdentifierDoc'];
        }

        if (requestParameters['multiplierIdentifierNumber'] != null) {
            queryParameters['multiplierIdentifierNumber'] = requestParameters['multiplierIdentifierNumber'];
        }

        if (requestParameters['typeIdentifierDoc'] != null) {
            queryParameters['typeIdentifierDoc'] = requestParameters['typeIdentifierDoc'];
        }

        if (requestParameters['identifierNumber'] != null) {
            queryParameters['identifierNumber'] = requestParameters['identifierNumber'];
        }

        if (requestParameters['seedUse'] != null) {
            queryParameters['seedUse'] = requestParameters['seedUse'];
        }

        if (requestParameters['uf'] != null) {
            queryParameters['uf'] = requestParameters['uf'];
        }

        if (requestParameters['city'] != null) {
            queryParameters['city'] = requestParameters['city'];
        }

        if (requestParameters['batch'] != null) {
            queryParameters['batch'] = requestParameters['batch'];
        }

        if (requestParameters['product'] != null) {
            queryParameters['Product'] = requestParameters['product'];
        }

        if (requestParameters['weightKq'] != null) {
            queryParameters['weightKq'] = requestParameters['weightKq'];
        }

        if (requestParameters['saleType'] != null) {
            queryParameters['saleType'] = requestParameters['saleType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xHarvest'] != null) {
            headerParameters['x-harvest'] = String(requestParameters['xHarvest']);
        }

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/invoices/notes/unprocessed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async invoicesNotesDataControllerFindAllUnprocessed(requestParameters: InvoicesNotesDataControllerFindAllUnprocessedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginationResponseDto> {
        const response = await this.invoicesNotesDataControllerFindAllUnprocessedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async invoicesNotesDataControllerFindAllWithoutQuotasRaw(requestParameters: InvoicesNotesDataControllerFindAllWithoutQuotasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginationResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['statusCategory'] != null) {
            queryParameters['statusCategory'] = requestParameters['statusCategory'];
        }

        if (requestParameters['dataMultiplierIdentifierNumber'] != null) {
            queryParameters['dataMultiplierIdentifierNumber'] = requestParameters['dataMultiplierIdentifierNumber'];
        }

        if (requestParameters['dataNfe'] != null) {
            queryParameters['dataNfe'] = requestParameters['dataNfe'];
        }

        if (requestParameters['dataIdentifierNumber'] != null) {
            queryParameters['dataIdentifierNumber'] = requestParameters['dataIdentifierNumber'];
        }

        if (requestParameters['dataUf'] != null) {
            queryParameters['dataUf'] = requestParameters['dataUf'];
        }

        if (requestParameters['dataCity'] != null) {
            queryParameters['dataCity'] = requestParameters['dataCity'];
        }

        if (requestParameters['dataBillingDate'] != null) {
            queryParameters['dataBillingDate'] = requestParameters['dataBillingDate'];
        }

        if (requestParameters['dataSeedUse'] != null) {
            queryParameters['dataSeedUse'] = requestParameters['dataSeedUse'];
        }

        if (requestParameters['dataCultivate'] != null) {
            queryParameters['dataCultivate'] = requestParameters['dataCultivate'];
        }

        if (requestParameters['dataQtyPackaging'] != null) {
            queryParameters['dataQtyPackaging'] = requestParameters['dataQtyPackaging'];
        }

        if (requestParameters['dataClientName'] != null) {
            queryParameters['dataClientName'] = requestParameters['dataClientName'];
        }

        if (requestParameters['dataBatch'] != null) {
            queryParameters['dataBatch'] = requestParameters['dataBatch'];
        }

        if (requestParameters['dataTechnology'] != null) {
            queryParameters['dataTechnology'] = requestParameters['dataTechnology'];
        }

        if (requestParameters['dataWeightPackaging'] != null) {
            queryParameters['dataWeightPackaging'] = requestParameters['dataWeightPackaging'];
        }

        if (requestParameters['dataWeightKq'] != null) {
            queryParameters['dataWeightKq'] = requestParameters['dataWeightKq'];
        }

        if (requestParameters['dataCfop'] != null) {
            queryParameters['dataCfop'] = requestParameters['dataCfop'];
        }

        if (requestParameters['editDataMultiplierIdentifierNumber'] != null) {
            queryParameters['editDataMultiplierIdentifierNumber'] = requestParameters['editDataMultiplierIdentifierNumber'];
        }

        if (requestParameters['editDataNfe'] != null) {
            queryParameters['editDataNfe'] = requestParameters['editDataNfe'];
        }

        if (requestParameters['editDataIdentifierNumber'] != null) {
            queryParameters['editDataIdentifierNumber'] = requestParameters['editDataIdentifierNumber'];
        }

        if (requestParameters['editDataUf'] != null) {
            queryParameters['editDataUf'] = requestParameters['editDataUf'];
        }

        if (requestParameters['editDataCity'] != null) {
            queryParameters['editDataCity'] = requestParameters['editDataCity'];
        }

        if (requestParameters['editDataBillingDate'] != null) {
            queryParameters['editDataBillingDate'] = requestParameters['editDataBillingDate'];
        }

        if (requestParameters['editDataSeedUse'] != null) {
            queryParameters['editDataSeedUse'] = requestParameters['editDataSeedUse'];
        }

        if (requestParameters['editDataCultivate'] != null) {
            queryParameters['editDataCultivate'] = requestParameters['editDataCultivate'];
        }

        if (requestParameters['editDataQtyPackaging'] != null) {
            queryParameters['editDataQtyPackaging'] = requestParameters['editDataQtyPackaging'];
        }

        if (requestParameters['editDataClientName'] != null) {
            queryParameters['editDataClientName'] = requestParameters['editDataClientName'];
        }

        if (requestParameters['editDataBatch'] != null) {
            queryParameters['editDataBatch'] = requestParameters['editDataBatch'];
        }

        if (requestParameters['editDataTechnology'] != null) {
            queryParameters['editDataTechnology'] = requestParameters['editDataTechnology'];
        }

        if (requestParameters['editDataWeightPackaging'] != null) {
            queryParameters['editDataWeightPackaging'] = requestParameters['editDataWeightPackaging'];
        }

        if (requestParameters['editDataWeightKq'] != null) {
            queryParameters['editDataWeightKq'] = requestParameters['editDataWeightKq'];
        }

        if (requestParameters['editDataCfop'] != null) {
            queryParameters['editDataCfop'] = requestParameters['editDataCfop'];
        }

        if (requestParameters['revision'] != null) {
            queryParameters['revision'] = requestParameters['revision'];
        }

        if (requestParameters['keyIntegration'] != null) {
            queryParameters['keyIntegration'] = requestParameters['keyIntegration'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xHarvest'] != null) {
            headerParameters['x-harvest'] = String(requestParameters['xHarvest']);
        }

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/invoices/notes/withoutquotas`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async invoicesNotesDataControllerFindAllWithoutQuotas(requestParameters: InvoicesNotesDataControllerFindAllWithoutQuotasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginationResponseDto> {
        const response = await this.invoicesNotesDataControllerFindAllWithoutQuotasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async invoicesNotesDataControllerGetRevissionsRaw(requestParameters: InvoicesNotesDataControllerGetRevissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginationResponseDto>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling invoicesNotesDataControllerGetRevissions().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xHarvest'] != null) {
            headerParameters['x-harvest'] = String(requestParameters['xHarvest']);
        }

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/invoices/revission/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async invoicesNotesDataControllerGetRevissions(requestParameters: InvoicesNotesDataControllerGetRevissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginationResponseDto> {
        const response = await this.invoicesNotesDataControllerGetRevissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async invoicesNotesDataControllerGetTotalsRaw(requestParameters: InvoicesNotesDataControllerGetTotalsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoicesNotesTotalsDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['statusCategory'] != null) {
            queryParameters['statusCategory'] = requestParameters['statusCategory'];
        }

        if (requestParameters['dataMultiplierIdentifierNumber'] != null) {
            queryParameters['dataMultiplierIdentifierNumber'] = requestParameters['dataMultiplierIdentifierNumber'];
        }

        if (requestParameters['dataNfe'] != null) {
            queryParameters['dataNfe'] = requestParameters['dataNfe'];
        }

        if (requestParameters['dataIdentifierNumber'] != null) {
            queryParameters['dataIdentifierNumber'] = requestParameters['dataIdentifierNumber'];
        }

        if (requestParameters['dataUf'] != null) {
            queryParameters['dataUf'] = requestParameters['dataUf'];
        }

        if (requestParameters['dataCity'] != null) {
            queryParameters['dataCity'] = requestParameters['dataCity'];
        }

        if (requestParameters['dataBillingDate'] != null) {
            queryParameters['dataBillingDate'] = requestParameters['dataBillingDate'];
        }

        if (requestParameters['dataSeedUse'] != null) {
            queryParameters['dataSeedUse'] = requestParameters['dataSeedUse'];
        }

        if (requestParameters['dataCultivate'] != null) {
            queryParameters['dataCultivate'] = requestParameters['dataCultivate'];
        }

        if (requestParameters['dataQtyPackaging'] != null) {
            queryParameters['dataQtyPackaging'] = requestParameters['dataQtyPackaging'];
        }

        if (requestParameters['dataClientName'] != null) {
            queryParameters['dataClientName'] = requestParameters['dataClientName'];
        }

        if (requestParameters['dataBatch'] != null) {
            queryParameters['dataBatch'] = requestParameters['dataBatch'];
        }

        if (requestParameters['dataTechnology'] != null) {
            queryParameters['dataTechnology'] = requestParameters['dataTechnology'];
        }

        if (requestParameters['dataWeightPackaging'] != null) {
            queryParameters['dataWeightPackaging'] = requestParameters['dataWeightPackaging'];
        }

        if (requestParameters['dataWeightKq'] != null) {
            queryParameters['dataWeightKq'] = requestParameters['dataWeightKq'];
        }

        if (requestParameters['dataCfop'] != null) {
            queryParameters['dataCfop'] = requestParameters['dataCfop'];
        }

        if (requestParameters['editDataMultiplierIdentifierNumber'] != null) {
            queryParameters['editDataMultiplierIdentifierNumber'] = requestParameters['editDataMultiplierIdentifierNumber'];
        }

        if (requestParameters['editDataNfe'] != null) {
            queryParameters['editDataNfe'] = requestParameters['editDataNfe'];
        }

        if (requestParameters['editDataIdentifierNumber'] != null) {
            queryParameters['editDataIdentifierNumber'] = requestParameters['editDataIdentifierNumber'];
        }

        if (requestParameters['editDataUf'] != null) {
            queryParameters['editDataUf'] = requestParameters['editDataUf'];
        }

        if (requestParameters['editDataCity'] != null) {
            queryParameters['editDataCity'] = requestParameters['editDataCity'];
        }

        if (requestParameters['editDataBillingDate'] != null) {
            queryParameters['editDataBillingDate'] = requestParameters['editDataBillingDate'];
        }

        if (requestParameters['editDataSeedUse'] != null) {
            queryParameters['editDataSeedUse'] = requestParameters['editDataSeedUse'];
        }

        if (requestParameters['editDataCultivate'] != null) {
            queryParameters['editDataCultivate'] = requestParameters['editDataCultivate'];
        }

        if (requestParameters['editDataQtyPackaging'] != null) {
            queryParameters['editDataQtyPackaging'] = requestParameters['editDataQtyPackaging'];
        }

        if (requestParameters['editDataClientName'] != null) {
            queryParameters['editDataClientName'] = requestParameters['editDataClientName'];
        }

        if (requestParameters['editDataBatch'] != null) {
            queryParameters['editDataBatch'] = requestParameters['editDataBatch'];
        }

        if (requestParameters['editDataTechnology'] != null) {
            queryParameters['editDataTechnology'] = requestParameters['editDataTechnology'];
        }

        if (requestParameters['editDataWeightPackaging'] != null) {
            queryParameters['editDataWeightPackaging'] = requestParameters['editDataWeightPackaging'];
        }

        if (requestParameters['editDataWeightKq'] != null) {
            queryParameters['editDataWeightKq'] = requestParameters['editDataWeightKq'];
        }

        if (requestParameters['editDataCfop'] != null) {
            queryParameters['editDataCfop'] = requestParameters['editDataCfop'];
        }

        if (requestParameters['revision'] != null) {
            queryParameters['revision'] = requestParameters['revision'];
        }

        if (requestParameters['keyIntegration'] != null) {
            queryParameters['keyIntegration'] = requestParameters['keyIntegration'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xHarvest'] != null) {
            headerParameters['x-harvest'] = String(requestParameters['xHarvest']);
        }

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/invoices/totals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoicesNotesTotalsDtoFromJSON(jsonValue));
    }

    /**
     */
    async invoicesNotesDataControllerGetTotals(requestParameters: InvoicesNotesDataControllerGetTotalsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoicesNotesTotalsDto> {
        const response = await this.invoicesNotesDataControllerGetTotalsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async invoicesNotesDataControllerSoftDeleteRaw(requestParameters: InvoicesNotesDataControllerSoftDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['statusCategory'] != null) {
            queryParameters['statusCategory'] = requestParameters['statusCategory'];
        }

        if (requestParameters['dataMultiplierIdentifierNumber'] != null) {
            queryParameters['dataMultiplierIdentifierNumber'] = requestParameters['dataMultiplierIdentifierNumber'];
        }

        if (requestParameters['dataNfe'] != null) {
            queryParameters['dataNfe'] = requestParameters['dataNfe'];
        }

        if (requestParameters['dataIdentifierNumber'] != null) {
            queryParameters['dataIdentifierNumber'] = requestParameters['dataIdentifierNumber'];
        }

        if (requestParameters['dataUf'] != null) {
            queryParameters['dataUf'] = requestParameters['dataUf'];
        }

        if (requestParameters['dataCity'] != null) {
            queryParameters['dataCity'] = requestParameters['dataCity'];
        }

        if (requestParameters['dataBillingDate'] != null) {
            queryParameters['dataBillingDate'] = requestParameters['dataBillingDate'];
        }

        if (requestParameters['dataSeedUse'] != null) {
            queryParameters['dataSeedUse'] = requestParameters['dataSeedUse'];
        }

        if (requestParameters['dataCultivate'] != null) {
            queryParameters['dataCultivate'] = requestParameters['dataCultivate'];
        }

        if (requestParameters['dataQtyPackaging'] != null) {
            queryParameters['dataQtyPackaging'] = requestParameters['dataQtyPackaging'];
        }

        if (requestParameters['dataClientName'] != null) {
            queryParameters['dataClientName'] = requestParameters['dataClientName'];
        }

        if (requestParameters['dataBatch'] != null) {
            queryParameters['dataBatch'] = requestParameters['dataBatch'];
        }

        if (requestParameters['dataTechnology'] != null) {
            queryParameters['dataTechnology'] = requestParameters['dataTechnology'];
        }

        if (requestParameters['dataWeightPackaging'] != null) {
            queryParameters['dataWeightPackaging'] = requestParameters['dataWeightPackaging'];
        }

        if (requestParameters['dataWeightKq'] != null) {
            queryParameters['dataWeightKq'] = requestParameters['dataWeightKq'];
        }

        if (requestParameters['dataCfop'] != null) {
            queryParameters['dataCfop'] = requestParameters['dataCfop'];
        }

        if (requestParameters['editDataMultiplierIdentifierNumber'] != null) {
            queryParameters['editDataMultiplierIdentifierNumber'] = requestParameters['editDataMultiplierIdentifierNumber'];
        }

        if (requestParameters['editDataNfe'] != null) {
            queryParameters['editDataNfe'] = requestParameters['editDataNfe'];
        }

        if (requestParameters['editDataIdentifierNumber'] != null) {
            queryParameters['editDataIdentifierNumber'] = requestParameters['editDataIdentifierNumber'];
        }

        if (requestParameters['editDataUf'] != null) {
            queryParameters['editDataUf'] = requestParameters['editDataUf'];
        }

        if (requestParameters['editDataCity'] != null) {
            queryParameters['editDataCity'] = requestParameters['editDataCity'];
        }

        if (requestParameters['editDataBillingDate'] != null) {
            queryParameters['editDataBillingDate'] = requestParameters['editDataBillingDate'];
        }

        if (requestParameters['editDataSeedUse'] != null) {
            queryParameters['editDataSeedUse'] = requestParameters['editDataSeedUse'];
        }

        if (requestParameters['editDataCultivate'] != null) {
            queryParameters['editDataCultivate'] = requestParameters['editDataCultivate'];
        }

        if (requestParameters['editDataQtyPackaging'] != null) {
            queryParameters['editDataQtyPackaging'] = requestParameters['editDataQtyPackaging'];
        }

        if (requestParameters['editDataClientName'] != null) {
            queryParameters['editDataClientName'] = requestParameters['editDataClientName'];
        }

        if (requestParameters['editDataBatch'] != null) {
            queryParameters['editDataBatch'] = requestParameters['editDataBatch'];
        }

        if (requestParameters['editDataTechnology'] != null) {
            queryParameters['editDataTechnology'] = requestParameters['editDataTechnology'];
        }

        if (requestParameters['editDataWeightPackaging'] != null) {
            queryParameters['editDataWeightPackaging'] = requestParameters['editDataWeightPackaging'];
        }

        if (requestParameters['editDataWeightKq'] != null) {
            queryParameters['editDataWeightKq'] = requestParameters['editDataWeightKq'];
        }

        if (requestParameters['editDataCfop'] != null) {
            queryParameters['editDataCfop'] = requestParameters['editDataCfop'];
        }

        if (requestParameters['revision'] != null) {
            queryParameters['revision'] = requestParameters['revision'];
        }

        if (requestParameters['keyIntegration'] != null) {
            queryParameters['keyIntegration'] = requestParameters['keyIntegration'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['xHarvest'] != null) {
            headerParameters['x-harvest'] = String(requestParameters['xHarvest']);
        }

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/invoices/notes`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async invoicesNotesDataControllerSoftDelete(requestParameters: InvoicesNotesDataControllerSoftDeleteRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateResponseDto> {
        const response = await this.invoicesNotesDataControllerSoftDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async invoicesNotesDataControllerUpdateRaw(requestParameters: InvoicesNotesDataControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateResponseDto>> {
        if (requestParameters['updateInvoicesNotesDataDto'] == null) {
            throw new runtime.RequiredError(
                'updateInvoicesNotesDataDto',
                'Required parameter "updateInvoicesNotesDataDto" was null or undefined when calling invoicesNotesDataControllerUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['statusCategory'] != null) {
            queryParameters['statusCategory'] = requestParameters['statusCategory'];
        }

        if (requestParameters['dataMultiplierIdentifierNumber'] != null) {
            queryParameters['dataMultiplierIdentifierNumber'] = requestParameters['dataMultiplierIdentifierNumber'];
        }

        if (requestParameters['dataNfe'] != null) {
            queryParameters['dataNfe'] = requestParameters['dataNfe'];
        }

        if (requestParameters['dataIdentifierNumber'] != null) {
            queryParameters['dataIdentifierNumber'] = requestParameters['dataIdentifierNumber'];
        }

        if (requestParameters['dataUf'] != null) {
            queryParameters['dataUf'] = requestParameters['dataUf'];
        }

        if (requestParameters['dataCity'] != null) {
            queryParameters['dataCity'] = requestParameters['dataCity'];
        }

        if (requestParameters['dataBillingDate'] != null) {
            queryParameters['dataBillingDate'] = requestParameters['dataBillingDate'];
        }

        if (requestParameters['dataSeedUse'] != null) {
            queryParameters['dataSeedUse'] = requestParameters['dataSeedUse'];
        }

        if (requestParameters['dataCultivate'] != null) {
            queryParameters['dataCultivate'] = requestParameters['dataCultivate'];
        }

        if (requestParameters['dataQtyPackaging'] != null) {
            queryParameters['dataQtyPackaging'] = requestParameters['dataQtyPackaging'];
        }

        if (requestParameters['dataClientName'] != null) {
            queryParameters['dataClientName'] = requestParameters['dataClientName'];
        }

        if (requestParameters['dataBatch'] != null) {
            queryParameters['dataBatch'] = requestParameters['dataBatch'];
        }

        if (requestParameters['dataTechnology'] != null) {
            queryParameters['dataTechnology'] = requestParameters['dataTechnology'];
        }

        if (requestParameters['dataWeightPackaging'] != null) {
            queryParameters['dataWeightPackaging'] = requestParameters['dataWeightPackaging'];
        }

        if (requestParameters['dataWeightKq'] != null) {
            queryParameters['dataWeightKq'] = requestParameters['dataWeightKq'];
        }

        if (requestParameters['dataCfop'] != null) {
            queryParameters['dataCfop'] = requestParameters['dataCfop'];
        }

        if (requestParameters['editDataMultiplierIdentifierNumber'] != null) {
            queryParameters['editDataMultiplierIdentifierNumber'] = requestParameters['editDataMultiplierIdentifierNumber'];
        }

        if (requestParameters['editDataNfe'] != null) {
            queryParameters['editDataNfe'] = requestParameters['editDataNfe'];
        }

        if (requestParameters['editDataIdentifierNumber'] != null) {
            queryParameters['editDataIdentifierNumber'] = requestParameters['editDataIdentifierNumber'];
        }

        if (requestParameters['editDataUf'] != null) {
            queryParameters['editDataUf'] = requestParameters['editDataUf'];
        }

        if (requestParameters['editDataCity'] != null) {
            queryParameters['editDataCity'] = requestParameters['editDataCity'];
        }

        if (requestParameters['editDataBillingDate'] != null) {
            queryParameters['editDataBillingDate'] = requestParameters['editDataBillingDate'];
        }

        if (requestParameters['editDataSeedUse'] != null) {
            queryParameters['editDataSeedUse'] = requestParameters['editDataSeedUse'];
        }

        if (requestParameters['editDataCultivate'] != null) {
            queryParameters['editDataCultivate'] = requestParameters['editDataCultivate'];
        }

        if (requestParameters['editDataQtyPackaging'] != null) {
            queryParameters['editDataQtyPackaging'] = requestParameters['editDataQtyPackaging'];
        }

        if (requestParameters['editDataClientName'] != null) {
            queryParameters['editDataClientName'] = requestParameters['editDataClientName'];
        }

        if (requestParameters['editDataBatch'] != null) {
            queryParameters['editDataBatch'] = requestParameters['editDataBatch'];
        }

        if (requestParameters['editDataTechnology'] != null) {
            queryParameters['editDataTechnology'] = requestParameters['editDataTechnology'];
        }

        if (requestParameters['editDataWeightPackaging'] != null) {
            queryParameters['editDataWeightPackaging'] = requestParameters['editDataWeightPackaging'];
        }

        if (requestParameters['editDataWeightKq'] != null) {
            queryParameters['editDataWeightKq'] = requestParameters['editDataWeightKq'];
        }

        if (requestParameters['editDataCfop'] != null) {
            queryParameters['editDataCfop'] = requestParameters['editDataCfop'];
        }

        if (requestParameters['revision'] != null) {
            queryParameters['revision'] = requestParameters['revision'];
        }

        if (requestParameters['keyIntegration'] != null) {
            queryParameters['keyIntegration'] = requestParameters['keyIntegration'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['xHarvest'] != null) {
            headerParameters['x-harvest'] = String(requestParameters['xHarvest']);
        }

        if (requestParameters['xOwnerId'] != null) {
            headerParameters['x-owner-id'] = String(requestParameters['xOwnerId']);
        }

        const response = await this.request({
            path: `/api/v1/invoices/notes`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateInvoicesNotesDataDtoToJSON(requestParameters['updateInvoicesNotesDataDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async invoicesNotesDataControllerUpdate(requestParameters: InvoicesNotesDataControllerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateResponseDto> {
        const response = await this.invoicesNotesDataControllerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
