import { Divider, Table, Typography } from "antd";
import { TableProps } from "antd/lib";

const columns: TableProps["columns"] = [
  {
    title: "Status",
    dataIndex: "status",
    rowScope: "row",
    onCell: (obj, index) => {
      if (obj.statusRowSpan) return { rowSpan: obj.statusRowSpan };
      return { rowSpan: 0 };
    },
  },
  {
    title: "Status descrição",
    dataIndex: "category",
    onCell: (_, index) => ({
      colSpan: index === 1 ? 5 : 1,
    }),
  },
];

const data = [
  {
    statusRowSpan: 8,
    status: "Fora da Política",
    category: "CFOP inválido",
  },
  { status: "Fora da Política", category: "Fora Política" },
  { status: "Fora da Política", category: "Bonificação Qualidade" },
  { status: "Fora da Política", category: "Cultivar Experimental" },
  { status: "Fora da Política", category: "Exportação" },
  { status: "Fora da Política", category: "Multiplicação" },
  { status: "Fora da Política", category: "Realocado Cota" },
  { status: "Fora da Política", category: "Sinistro" },
];

const billingExtraInfo = (
  <>
    <Divider orientation="left" style={{ marginTop: 35 }}>
      Informações adicionais
    </Divider>
    <Typography.Text italic type="secondary">
      A maior parte dos status ao processar o arquivo enviado é determinado de
      forma automatica pelo sistema, porem os status e descrições referente a
      "Fora da política" podem ser indicados diretamente na planilha de
      importação com a seguinte nomeclatura:
    </Typography.Text>
    <Table
      columns={columns}
      dataSource={data}
      bordered
      title={() => "Status elegível da alteração"}
    />
  </>
);

export default billingExtraInfo;
