import React, { useEffect, useRef } from "react";

import { ActionType, ProTable } from "@ant-design/pro-components";

import { Divider, Typography } from "antd";
import {
  DevolutionsInvoicesNotesDataApi,
  InvoicesNotesDataApi,
  InvoicesNotesDataControllerFindAllRequest,
  RoyaltiesNotesDataApi,
  RoyaltiesNotesDataControllerFindAllRequest,
} from "../../client-api";
import { useAPI } from "../../client-api/utils/api";

import { cleanObj } from "../utils";
import { royaltiesNotesDataColumns } from "./royaltiesNotesDataColumns";
import { clientDevolutionNotesColumns } from "./clientDevolutionNotesColumns";
import { clientBillingColumns } from "./clientBillingColumns";

type InvoicesRecordsProps = {
  keyIntegration: string;
};

const InvoicesRecords: React.FC<InvoicesRecordsProps> = (props) => {
  const { keyIntegration } = props;
  const actionRefInvoicesNotes = useRef<ActionType>();
  const actionRefRoyaltiesNotes = useRef<ActionType>();
  const actionRefDevolutionInvoicesNotes = useRef<ActionType>();
  const invoicesNotesApi = useAPI(InvoicesNotesDataApi);
  const devolutionsInvoicesNotesApi = useAPI(DevolutionsInvoicesNotesDataApi);
  const royaltiesNotesApi = useAPI(RoyaltiesNotesDataApi);

  useEffect(() => {
    actionRefInvoicesNotes?.current?.reload();
    actionRefRoyaltiesNotes?.current?.reload();
    actionRefDevolutionInvoicesNotes?.current?.reload();
  }, [keyIntegration]);

  return (
    <>
      <Divider orientation="left" orientationMargin="0">
        <Typography.Text type="secondary">ITS</Typography.Text>
      </Divider>
      <ProTable
        bordered
        scroll={{ x: "100vw", y: "100vh" }}
        actionRef={actionRefRoyaltiesNotes}
        columns={royaltiesNotesDataColumns}
        beforeSearchSubmit={(params) => {
          const p = cleanObj(params);
          return p;
        }}
        request={(params) => {
          const query = {
            page: params?.current,
            pageSize: params?.pageSize,
            ...params,
          } as RoyaltiesNotesDataControllerFindAllRequest;
          query.keyIntegration = keyIntegration;
          return royaltiesNotesApi
            .royaltiesNotesDataControllerFindAll(query)
            .then((data) => {
              return {
                data: data.list,
                success: true,
                total: data.total,
              };
            });
        }}
        pagination={{
          pageSize: 5,
          showSizeChanger: false,
        }}
        rowKey="id"
        search={false}
        options={false}
      />
      <Divider orientation="left" orientationMargin="0">
        <Typography.Text type="secondary">Faturamento</Typography.Text>
      </Divider>
      <ProTable
        bordered
        scroll={{ x: "100vw", y: "100vh" }}
        actionRef={actionRefInvoicesNotes}
        columns={clientBillingColumns}
        beforeSearchSubmit={(params) => {
          const p = cleanObj(params);
          return p;
        }}
        request={(params) => {
          const query = {
            page: params?.current,
            pageSize: params?.pageSize,
            ...params,
          } as InvoicesNotesDataControllerFindAllRequest;
          query.keyIntegration = keyIntegration;
          return invoicesNotesApi
            .invoicesNotesDataControllerFindAll(query)
            .then((data) => {
              return {
                data: data.list,
                success: true,
                total: data.total,
              };
            });
        }}
        pagination={{
          pageSize: 5,
          showSizeChanger: false,
        }}
        rowKey="id"
        search={false}
        options={false}
      />
      <Divider orientation="left" orientationMargin="0">
        <Typography.Text type="secondary">Notas Devolução</Typography.Text>
      </Divider>
      <ProTable
        actionRef={actionRefDevolutionInvoicesNotes}
        columns={clientDevolutionNotesColumns}
        bordered
        beforeSearchSubmit={(params) => {
          const p = cleanObj(params);
          return p;
        }}
        request={(params) => {
          const query = {
            page: params?.current,
            pageSize: params?.pageSize,
            ...params,
          } as InvoicesNotesDataControllerFindAllRequest;
          query.keyIntegration = keyIntegration;
          return devolutionsInvoicesNotesApi
            .devolutionsInvoicesNotesDataControllerFindAll(query)
            .then((data) => {
              return {
                data: data.list,
                success: true,
                total: data.total,
              };
            });
        }}
        pagination={{
          pageSize: 5,
          showSizeChanger: false,
        }}
        rowKey="id"
        search={false}
        options={false}
      />
    </>
  );
};

export default InvoicesRecords;
