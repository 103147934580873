import { ProColumns } from "@ant-design/pro-components";
import EditDataFields from "../../pages/client-billing/data-fields";
import { textToIdentificationNumber } from "../utils";

export const clientDevolutionNotesColumns: ProColumns[] = [
  {
    title: "id",
    dataIndex: "id",
    hidden: true,
    hideInSearch: true,
  },
  {
    title: EditDataFields["editDataDevolutionNfe"],
    dataIndex: "editDataDevolutionNfe",
    ellipsis: true,
    width: 120,
  },
  {
    title: EditDataFields["editDataNfe"],
    dataIndex: "editDataNfe",
    ellipsis: true,
    width: 60,
  },
  {
    title: EditDataFields["editDataBatch"],
    dataIndex: "editDataBatch",
    ellipsis: true,
    width: 60,
  },
  {
    title: EditDataFields["editDataWeightKq"],
    dataIndex: "editDataWeightKq",
    ellipsis: true,
    width: 80,
  },
  {
    title: EditDataFields["editDataIdentifierNumber"],
    dataIndex: "editDataIdentifierNumber",
    ellipsis: true,
    width: 200,
    renderText: (text) => textToIdentificationNumber(text),
  },
  {
    title: EditDataFields["editDataCustomerName"],
    dataIndex: "editDataCustomerName",
    ellipsis: true,
    width: 200,
  },
  {
    title: EditDataFields["editDataMultiplierIdentifierNumber"],
    dataIndex: "editDataMultiplierIdentifierNumber",
    ellipsis: true,
    width: 200,
    renderText: (text) => textToIdentificationNumber(text),
  },
];
