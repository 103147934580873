import React, { useState, createRef, useEffect } from "react";
import { Button, Drawer, Empty, Select, Space, Typography } from "antd";
import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import type { ProColumns } from "@ant-design/pro-components";
import { ProTable, ActionType } from "@ant-design/pro-components";
import { useAPI } from "../../../client-api/utils/api";
import { ReportsApi } from "../../../client-api";
import {
  cleanObj,
  textToIdentificationNumber,
} from "../../../components/utils";
import { InvoiceStatusList } from "../../../components/invoices-status/types";
import TagInvoiceStatus from "../../../components/invoices-status/tag-invoice-status";
import InvoicesRecords from "../../../components/invoices-records/indes";
// import ClientBillingEdit from "../../../components/client-billing-edit/indes";

import "./style.css";
import TagInvoiceStatusDescription from "../../../components/invoices-status/tag-invoice-status-description";

const InvoiceRecords: React.FC<{ status?: string }> = ({ status }) => {
  const api = useAPI(ReportsApi);
  const actionRef: any = createRef<ActionType>();

  const [keyIntegration, setKeyIntegration] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (actionRef.current) {
      actionRef.current.reload(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const columns: ProColumns[] = [
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 100,
      hideInSearch: !!status,
      filters: InvoiceStatusList,
      renderText: (value: string) => (
        <>
          <TagInvoiceStatus value={value} />
        </>
      ),
      renderFormItem: (__, props) => (
        <Select {...props} options={InvoiceStatusList} />
      ),
    },
    {
      title: "Descrição",
      key: "statusCategory",
      dataIndex: "statusCategory",
      width: 100,
      renderText: (value: string, record) => (
        <TagInvoiceStatusDescription value={value} status={record.status} />
      ),
    },
    {
      title: "NF",
      key: "nfe",
      dataIndex: "nfe",
      width: 64,
      // ...defaultFilter("nfe"),
    },
    {
      title: "cpf/cnpj",
      key: "cpu",
      width: 100,
      ellipsis: false,
      dataIndex: "identifierNumber",
      renderText: (text) => (
        <Typography.Text title={text} copyable={{ text }}>
          {textToIdentificationNumber(text)}
        </Typography.Text>
      ),
      // ...defaultFilter("identifierNumber"),
    },
    {
      title: "Lote",
      key: "batch",
      width: 100,
      dataIndex: "batch",
      renderText: (text) => (
        <Typography.Text title={text} copyable={{ text }}>
          {text}
        </Typography.Text>
      ),
      // ellipsis: true,
      // ...defaultFilter("batch"),
    },
    {
      title: "Tecnologia",
      key: "technology",
      width: 100,
      dataIndex: "technology",
      renderText: (text) => (
        <Typography.Text title={text} copyable={{ text }}>
          {text}
        </Typography.Text>
      ),
      // ...defaultFilter("technology"),
    },
    {
      title: "Uso da semente",
      key: "seedUse",
      width: 100,
      dataIndex: "seedUse",
      // ...defaultFilter("seedUse"),
    },
    {
      title: "Qtd Faturamento",
      key: "invoicesTotalQty",
      width: 50,
      // ellipsis: true,
      dataIndex: "invoicesTotalQty",
      renderText(_, record) {
        return (
          <Typography.Text>
            {record.invoicesTotalQty}
            {record.devolutionsInvoicesTotalQty && (
              <Typography.Text type="danger">
                {" "}
                ({record.devolutionsInvoicesTotalQty})
              </Typography.Text>
            )}
          </Typography.Text>
        );
      },
    },
    {
      title: "Qtd devolucao",
      key: "devolutionsInvoicesTotalQty",
      width: 50,
      hideInTable: true,
      hideInSearch: true,
      dataIndex: "devolutionsInvoicesTotalQty",
    },
    {
      title: "Qtd ITS",
      key: "royaltiesTotalQty",
      width: 50,
      // ellipsis: true,
      dataIndex: "royaltiesTotalQty",
    },
    {
      title: "Diferença",
      key: "diff",
      width: 50,
      renderText(_, record) {
        return (
          record.invoicesTotalQty -
          record.devolutionsInvoicesTotalQty -
          record.royaltiesTotalQty
        );
      },
    },
    {
      hideInTable: true,
      key: "keyIntegration",
      hideInSearch: true,
      dataIndex: "keyIntegration",
    },
    {
      title: <EllipsisOutlined />,
      fixed: "right",
      key: "options",
      hideInSearch: true,
      width: 32,
      render: (_, record) => (
        <Space.Compact block>
          <Button
            icon={<SearchOutlined />}
            size="small"
            onClick={() => {
              if (record.keyIntegration) {
                setOpen(true);
                setKeyIntegration(record.keyIntegration);
              }
            }}
          />
        </Space.Compact>
      ),
    },
  ];

  return (
    <>
      <ProTable
        actionRef={actionRef}
        loading={loading}
        columns={columns}
        beforeSearchSubmit={(params) => {
          const p = cleanObj(params);
          return p;
        }}
        request={(params, _, filter) => {
          setLoading(true);
          const filters = { ...filter, ...params };
          delete filters.current;
          delete filters.pageSize;
          if (status) filters.status = status;
          const getItems = api.reportsControllerGetNotes({
            page: params?.current,
            pageSize: params?.pageSize,
            ...filters,
          });

          return getItems
            .then((data) => {
              return {
                data: data.list,
                success: true,
                total: data.total,
              };
            })
            .finally(() => setLoading(false));
        }}
        rowKey="keyIntegration"
        sticky={{ offsetHeader: 56 }}
        pagination={{ defaultPageSize: 100 }}
        scroll={{ x: "100vw", y: "100vh" }}
        search={{ layout: "vertical", defaultColsNumber: 4 }}
      />
      <Drawer
        open={open}
        title={"Registros"}
        onClose={() => setOpen(false)}
        width={window.innerWidth * 0.9}
      >
        {keyIntegration ? (
          <InvoicesRecords keyIntegration={keyIntegration} />
        ) : (
          <Empty />
        )}
      </Drawer>
    </>
  );
};

export default InvoiceRecords;
