import { PageContainer } from "@ant-design/pro-components";
import InvoiceRecords from "./components/invoice-records";
import { Avatar, Card } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { useState } from "react";
import { InvoiceStatusDef } from "../../components/invoices-status/types";
import { useAPI } from "../../client-api/utils/api";
import { ReportsApi } from "../../client-api";
import { useAsyncRetry } from "react-use";

const HomePage = () => {
  const api = useAPI(ReportsApi);
  const [activeTabKey, setActiveTabKey] = useState<string>("all");

  const tabList = useAsyncRetry(async () => {
    return api.reportsControllerGetStatus().then(async (data) => {
      const main = [
        {
          key: "all",
          label: "Todas",
          icon: <FileTextOutlined />,
          disabled: false,
        },
      ];
      data.forEach((item) => {
        if (item.tryMatch && InvoiceStatusDef[item.slug]) {
          main.push({
            key: item.slug,
            label: `${InvoiceStatusDef[item.slug].label}: ${item.count ?? ""}`,
            icon: (
              <Avatar
                style={{ backgroundColor: InvoiceStatusDef[item.slug].color }}
                icon={InvoiceStatusDef[item.slug].icon}
              />
            ),
            disabled: item.count === 0,
          });
        }
      });
      return main;
    });
  });

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  return (
    <PageContainer title={false}>
      <Card
        loading={tabList.loading}
        style={{ width: "100%" }}
        title={false}
        tabList={tabList.value}
        tabProps={{ size: "large", type: "card" }}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
      >
        <InvoiceRecords
          status={activeTabKey === "all" ? undefined : activeTabKey}
        />
      </Card>
    </PageContainer>
  );
};

export default HomePage;
