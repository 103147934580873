// src/pages/HomePage.js
import React, { createRef, useState } from "react";
import {
  ActionType,
  PageContainer,
  ProColumns,
  ProTable,
} from "@ant-design/pro-components";
import { useAPI } from "../../client-api/utils/api";
import {
  RoyaltiesNotesDataApi,
  RoyaltiesNotesDataControllerFindAllRequest,
} from "../../client-api";
import BgProcessPopover from "../../components/bg-process-popover";
import { cleanObj, textToIdentificationNumber } from "../../components/utils";

export const royaltiesNotesDataColumns: ProColumns[] = [
  {
    title: "id",
    dataIndex: "id",
    hidden: true,
    hideInSearch: true,
  },
  {
    dataIndex: "saleType",
    title: "Tipo da Venda",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "invoice",
    title: "Nota Fiscal",
    hidden: false,
    width: 150,
    hideInSearch: false,
  },
  {
    dataIndex: "invoiceDate",
    title: "Data da Nota Fiscal",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "creationDate",
    title: "Data de Criação",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "batch",
    title: "Lote",
    hidden: false,
    width: 150,
    hideInSearch: false,
  },
  {
    dataIndex: "quantity",
    title: "Quantidade",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "product",
    title: "Produto",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "buyerName",
    title: "Nome do Comprador",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "buyerIdentifierNumber",
    title: "Nº Doc CNPJ/CPF Comprador",
    hidden: false,
    width: 150,
    renderText: (value) => textToIdentificationNumber(value),
    hideInSearch: false,
  },
  {
    dataIndex: "sellerName",
    title: "Nome do Vendedor",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "sellerIdentifierNumber",
    title: "Nº Doc CNPJ/CPF do Vendedor",
    hidden: false,
    width: 150,
    renderText: (text) => textToIdentificationNumber(text),
    hideInSearch: true,
  },
  {
    dataIndex: "creditVolume",
    title: "Volume de Crédito",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "netUnitPrice",
    title: "Valor Unitário Liquido (R$)",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "calculatedNetValue",
    title: "Valor Calculado Liquido (NET) (R$)",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "billingType",
    title: "Tipo de Cobrança",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "dueDate",
    title: "Data de vencimento",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "plantingState",
    title: "Estado de Plantio",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "plantingCity",
    title: "Cidade de Plantio",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "harvest",
    title: "Safra",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "brand",
    title: "Marca",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "technology",
    title: "Tecnologia",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "saleStatus",
    title: "Status da Venda",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "sellerState",
    title: "UF do Vendedor",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
  {
    dataIndex: "sellerCity",
    title: "Cidade do Vendedor",
    hidden: false,
    width: 150,
    hideInSearch: true,
  },
];

const RoyaltiesNotes: React.FC = () => {
  const api = useAPI(RoyaltiesNotesDataApi);

  const [loading, setLoading] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<any>();

  const actionRef: any = createRef<ActionType>();

  return (
    <PageContainer title={false}>
      <ProTable
        // antd site header height
        scroll={{ x: "100vw", y: "100vh" }}
        sticky={{ offsetHeader: 56 }}
        loading={loading}
        columns={royaltiesNotesDataColumns}
        actionRef={actionRef}
        beforeSearchSubmit={(params) => {
          const p = cleanObj(params);
          return p;
        }}
        request={(params, sorter, filter) => {
          setLoading(true);
          const query = {
            page: params?.current,
            pageSize: params?.pageSize,
            ...params,
          } as RoyaltiesNotesDataControllerFindAllRequest;
          setCurrentFilters(params);
          return api
            .royaltiesNotesDataControllerFindAll(query)
            .then((data) => {
              return {
                data: data.list,
                success: true,
                total: data.total,
              };
            })
            .finally(() => setLoading(false));
        }}
        rowKey="id"
        pagination={{ defaultPageSize: 100 }}
        search={{ filterType: "query", layout: "vertical" }}
        dateFormatter="string"
        toolBarRender={() => [
          <BgProcessPopover
            action="export"
            type={["royaltiesNotes"]}
            doExport={() =>
              api.royaltiesNotesDataControllerExportAll({
                ...currentFilters,
              })
            }
          />,
        ]}
      />
    </PageContainer>
  );
};

export default RoyaltiesNotes;
