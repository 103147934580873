// src/pages/HomePage.js
import React, { createRef, useState } from "react";
import {
  ActionType,
  PageContainer,
  ProColumns,
  ProTable,
} from "@ant-design/pro-components";
import { Select, Space } from "antd";
import { useAPI } from "../../client-api/utils/api";
import { InvoicesNotesDataApi } from "../../client-api";
import { EllipsisOutlined } from "@ant-design/icons";

import ClientBillingEdit from "../../components/client-billing-edit/indes";
import TagInvoiceStatus from "../../components/invoices-status/tag-invoice-status";
import { InvoiceStatusDef } from "../../components/invoices-status/types";
import BgProcessPopover from "../../components/bg-process-popover";
import { cleanObj } from "../../components/utils";

const UnprocessedRoyaltiesNotes: React.FC = () => {
  const actionRef: any = createRef<ActionType>();
  const api = useAPI(InvoicesNotesDataApi);

  const [loading, setLoading] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<any>();

  const clientBillingColumns: ProColumns[] = [
    {
      title: "id",
      dataIndex: "id",
      hidden: true,
      hideInSearch: true,
    },
    {
      title: <EllipsisOutlined />,
      fixed: "right",
      key: "options",
      hideInSearch: true,
      width: 64,
      render: (_, record) => (
        <Space.Compact block>
          <ClientBillingEdit
            filters={{ id: record.id }}
            iconMode
            onConfirm={() => actionRef?.current?.reload()}
            size="small"
          />
        </Space.Compact>
      ),
    },
    {
      title: "status",
      dataIndex: "status",
      width: 100,
      renderText: (value: string, record) => <TagInvoiceStatus value={value} />,
      renderFormItem: (__, props) => (
        <Select
          {...props}
          options={Object.keys(InvoiceStatusDef).map((el) => ({
            value: el,
            label: InvoiceStatusDef[el].label,
          }))}
        />
      ),
    },
    {
      title: "NOTA FISCAL",
      dataIndex: "nfe",
      width: 200,
    },
    {
      title: "TIPO DE VENDEDOR",
      dataIndex: "type",
      hideInSearch: true,
      width: 150,
    },
    {
      title: "DATA DE EMISSAO",
      dataIndex: "billingDate",
      width: 100,
    },
    {
      title: "NUM. DOC. VENDEDOR",
      dataIndex: "multiplierIdentifierNumber",
      width: 200,
    },
    {
      title: "TIPO DOC. VENDEDOR",
      dataIndex: "multiplierTypeIdentifierDoc",
      hideInSearch: true,
      width: 200,
    },
    {
      title: "NUM. DOC. COMPRADOR",
      dataIndex: "identifierNumber",
      width: 200,
    },
    {
      title: "TIPO. DOC. COMPRADOR",
      dataIndex: "typeIdentifierDoc",
      hideInSearch: true,
      width: 200,
    },
    {
      title: "TIPO DE COMPRADOR",
      dataIndex: "seedUse",
      width: 200,
    },
    {
      title: "UF",
      dataIndex: "uf",
      width: 200,
    },
    {
      title: "CIDADE",
      dataIndex: "city",
      width: 200,
    },
    {
      title: "LOTE",
      dataIndex: "batch",
      width: 200,
    },
    {
      title: "PRODUTO",
      dataIndex: "Product",
      width: 200,
      hideInSearch: true,
    },
    {
      title: "QTD",
      dataIndex: "weightKq",
      width: 80,
    },
    {
      title: "TIPO DE VENDA",
      dataIndex: "saleType",
      hideInSearch: true,
      width: 80,
    },
  ];

  return (
    <PageContainer title={false}>
      <ProTable
        scroll={{ x: "100vw", y: "100vh" }}
        sticky={{ offsetHeader: 56 }}
        loading={loading}
        search={{ layout: "vertical" }}
        columns={clientBillingColumns}
        actionRef={actionRef}
        beforeSearchSubmit={(params) => {
          const p = cleanObj(params);
          return p;
        }}
        request={(params, sorter, filter) => {
          setLoading(true);
          // console.log(filter, params);
          const filterInvoices = { ...filter, ...params };
          delete filterInvoices.current;
          delete filterInvoices.pageSize;
          // console.log(filter, params, filterInvoices);
          setCurrentFilters(filterInvoices);
          const getItems = api.invoicesNotesDataControllerFindAllUnprocessed({
            page: params?.current,
            pageSize: params?.pageSize,
            ...filterInvoices,
          });
          return getItems
            .then((data) => {
              return {
                data: data.list,
                success: true,
                total: data.total,
              };
            })
            .finally(() => setLoading(false));
        }}
        rowKey="id"
        pagination={{ defaultPageSize: 100 }}
        dateFormatter="string"
        toolBarRender={() => [
          <BgProcessPopover
            action="export"
            type={["unprocessedNotes"]}
            doExport={() =>
              api.invoicesNotesDataControllerExportAllUnprocessed({
                ...currentFilters,
              })
            }
          />,
        ]}
      />
    </PageContainer>
  );
};

export default UnprocessedRoyaltiesNotes;
