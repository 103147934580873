import { useState } from "react";
import {
  Avatar,
  Divider,
  Dropdown,
  Flex,
  Modal,
  Radio,
  Select,
  Space,
  Typography,
} from "antd";
import { ShopOutlined, CalendarOutlined } from "@ant-design/icons";
import { useAsyncRetry } from "react-use";

import { useAPI } from "../../client-api/utils/api";
import { HarvestApi, OwnerApi, OwnerDTOToJSON } from "../../client-api";
import { useGlobalApp } from "../global-app-provider";
import { Owner } from "../global-app-provider/provider";

interface Props {
  onSwitcher: () => void;
}

const SwitchClient = (props: Props) => {
  const { onSwitcher } = props;
  const apiOwner = useAPI(OwnerApi);
  const apiHarvest = useAPI(HarvestApi);
  const { setOwner, getOwner, setHarvest, getHarvest } = useGlobalApp();
  const currentOwner = getOwner();
  const currentHarvest = getHarvest();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectOwner, setSelectOwner] = useState<Owner | false>(false);

  const handleOk = () => {
    setOwner(selectOwner);
    setIsModalOpen(false);
    onSwitcher();
  };

  const stateApiOwner = useAsyncRetry(async () => {
    return await apiOwner.ownerControllerFindAll().then((owners) => {
      return owners.map((o) => OwnerDTOToJSON(o));
    });
  }, []);

  const stateApiHarvest = useAsyncRetry(async () => {
    return await apiHarvest.harvestControllerFindAll().then((harvest) => {
      return harvest.list;
    });
  }, []);

  const harvestOptions = stateApiHarvest.value?.map((har: any) => ({
    value: har.slug,
    label: har.name,
  }));

  const needSelectDatas = !currentOwner || !currentHarvest;
  const showModal: boolean = needSelectDatas || isModalOpen;

  return (
    <>
      {stateApiOwner.loading && (
        <Avatar
          style={{ backgroundColor: "#87d068" }}
          icon={<ShopOutlined />}
        />
      )}
      {stateApiOwner.loading && (
        <Typography.Text>Carregando...</Typography.Text>
      )}
      {stateApiOwner.error && (
        <Typography.Text type="danger">
          {stateApiOwner.error.message}
        </Typography.Text>
      )}

      {showModal && (
        <Modal
          destroyOnClose
          title="Selecione um cliente"
          open={showModal}
          onOk={handleOk}
          closable={false}
          cancelButtonProps={{
            disabled: !currentOwner,
          }}
          onCancel={() => setIsModalOpen(false)}
        >
          <Space
            style={{ padding: "10px" }}
            direction="vertical"
            size={"large"}
          >
            <Space direction="horizontal">
              <Typography>Safra:</Typography>
              <Select
                suffixIcon={<CalendarOutlined />}
                style={{ width: 240 }}
                options={harvestOptions}
                loading={stateApiHarvest.loading}
                onChange={(e, option) => {
                  const op = Array.isArray(option) ? option[0] : option;
                  setHarvest({
                    slug: op.value,
                    name: op.label,
                  });
                }}
              />
            </Space>

            <Radio.Group
              onChange={(e) => setSelectOwner(e.target.value)}
              buttonStyle="solid"
            >
              <Flex
                gap="middle"
                align="center"
                justify="space-between"
                wrap="wrap"
              >
                {stateApiOwner.loading && (
                  <Typography.Text>Carregando...</Typography.Text>
                )}
                {stateApiOwner.value?.map((c) => (
                  <Radio value={c} key={c.id}>
                    <Space direction="vertical" align="center">
                      <Avatar
                        style={{ backgroundColor: "#87d068" }}
                        icon={<ShopOutlined />}
                      />
                      {c.name}
                    </Space>
                  </Radio>
                ))}
              </Flex>
            </Radio.Group>
          </Space>
        </Modal>
      )}
      <Dropdown
        menu={{
          items: [
            {
              key: "showOwnerList",
              label: "alterar",
              onClick: () => {
                setIsModalOpen(true);
              },
            },
          ],
        }}
      >
        <Typography.Text>
          {!needSelectDatas ? (
            <Space>
              <ShopOutlined /> {currentOwner?.name}
              <Divider type="vertical" />
              <Typography.Text strong>
                <CalendarOutlined /> {currentHarvest?.name}
              </Typography.Text>
            </Space>
          ) : (
            "Selecione uma empresa"
          )}
        </Typography.Text>
      </Dropdown>
    </>
  );
};

export default SwitchClient;
