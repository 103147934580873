import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Select, Space, Typography, Upload } from "antd";
import {
  getEndpoint,
  useAPIConfiguration,
} from "../../../client-api/utils/api";
import { useGlobalApp } from "../../../components/global-app-provider";

// import fileBillingTemplate from "./public/faturamento_template.xlsx";

const { Dragger } = Upload;

interface Props {
  title: string;
  description: string;
  urlTemplate: string | string[];
  ownerId: number | string;
  reporttype:
    | "client_billing"
    | "client_devolution"
    | "royalties_notes"
    | "its_quotas";
  mappers: any[];
  extraInfo?: React.ReactNode;
}

const ImportCard: React.FC<Props> = (props: Props) => {
  const { title, description, urlTemplate, reporttype, mappers, extraInfo } =
    props;
  const { getOwner, getHarvest } = useGlobalApp();
  const currentOwner = getOwner();
  const currentHarvest = getHarvest();
  const config = useAPIConfiguration(
    currentOwner !== false ? currentOwner.id : "-1",
    currentHarvest !== false ? currentHarvest.slug : "-1"
  );
  const headers = config.headers;
  delete headers?.["Content-Type"];

  const [mapper, setMapper] = useState<number>(mappers?.[0]?.value);

  const propsImportReports: UploadProps = {
    name: "file",
    multiple: false,
    headers: headers,
    onChange(info) {
      const { status } = info.file;
      // if (status !== "uploading") {
      //   console.log(info.file, info.fileList);
      // }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const defaultValue = mappers?.[0]?.value;

  const importReportBilling = (
    <Dragger
      {...propsImportReports}
      action={getEndpoint(
        `api/v1/source/import/xls?mapper=${
          mapper || defaultValue
        }&reporttype=${reporttype}`
      )}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Clique ou arraste o arquivo para esta área para fazer upload
      </p>
    </Dragger>
  );

  if (mappers.length <= 0) {
    return <>"não existe mapeadores definidos para esse tipo de relatorio"</>;
  }

  return (
    <Space direction="vertical">
      <Typography.Title level={5}>
        {title}
        {(typeof urlTemplate === "string" ? [urlTemplate] : urlTemplate).map(
          (url) => (
            <a href={url} download={url.substring(url.lastIndexOf("/") + 1)}>
              {" "}
              (baixar templates)
            </a>
          )
        )}
      </Typography.Title>
      <>
        Seleciona um template de importação:
        <Select
          placeholder="Selecione um mapeamento"
          options={mappers}
          defaultValue={defaultValue}
          onChange={(v) => setMapper(v)}
        />
        <Typography.Text>{description}</Typography.Text>
      </>
      {importReportBilling}
      <>{extraInfo}</>
    </Space>
  );
};

export default ImportCard;
