import { Popover, Typography } from "antd";
import { CommentOutlined } from "@ant-design/icons";

interface Props {
  status: string;
  value: string;
}

const labels: { [key: string]: any } = {
  report_completed: {
    reported: { label: "Reportado", color: "#52c41a" },
    devolution: { label: "Devolução completa", color: "#52c41a" },
  },
  inconsistent_report: {
    divergent_quantity: { label: "Quantidade divergente", color: "#ff9c6e" },
    incomplete_devolution: { label: "Devolução imcompleta", color: "#ff9c6e" },
  },
  canceled: {
    completed: { label: "Cancelamento completo", color: "#ff4d4f" },
    incomplete_canceled: { label: "Cancelamento imcompleto", color: "#ff4d4f" },
  },
  miss_invoices: {
    miss_invoices: { label: "Faturas não localizadas", color: "#b37feb" },
  },
  not_reported: {
    not_reported: { label: "Não reportado", color: "#69b1ff" },
  },
  out_politics: {
    sinistro: { label: "Sinistro", color: "#d46b08" },
    exportacao: { label: "Exportação", color: "#d46b08" },
    out_politics: { label: "Fora politica", color: "#d46b08" },
    cfop_invalid: { label: "CFOP inválido", color: "#d46b08" },
    multiplicacao: { label: "Multiplicação", color: "#d46b08" },
    realocado_cota: { label: "Realocado Cota", color: "#d46b08" },
    cultivar_experimental: { label: "Cultivar Experimental", color: "#d46b08" },
    bonificacao_qualidade: { label: "Bonificação Qualidade", color: "#d46b08" },
  },
};

const TagInvoiceStatusDescription = (props: Props) => {
  const status = labels[props.status] ?? {};
  return (
    <Typography.Text strong>
      <Typography.Text style={{ color: status[props.value]?.color ?? "#bbb" }}>
        <CommentOutlined />
      </Typography.Text>
      <Typography.Text type="secondary">
        {" "}
        {status[props.value]?.label ?? (
          <Popover
            content={"Descrição fora do padrão."}
            title={false}
            trigger="hover"
          >
            <Typography.Text strong={false} underline>
              {props?.value?.toLowerCase()}
            </Typography.Text>
          </Popover>
        )}
      </Typography.Text>
    </Typography.Text>
  );
};

export default TagInvoiceStatusDescription;
