import {
  FileOutlined,
  FileUnknownOutlined,
  FileDoneOutlined,
  FileExcelOutlined,
  FileExclamationOutlined,
  DislikeOutlined,
} from "@ant-design/icons";

export const InvoiceStatusDef: { [key: string]: any } = {
  not_reported: {
    label: "Não reportado",
    color: "#69b1ff",
    icon: <FileOutlined />,
  },
  report_completed: {
    label: "Concuído",
    color: "#52c41a",
    icon: <FileDoneOutlined />,
  },
  inconsistent_report: {
    label: "Inconsistente",
    color: "#ff9c6e",
    icon: <FileExclamationOutlined />,
  },
  miss_invoices: {
    label: "Faturas não localizadas",
    color: "#b37feb",
    icon: <FileUnknownOutlined />,
  },
  canceled: {
    label: "Nota Cancelada",
    color: "#ff4d4f",
    icon: <FileExcelOutlined />,
  },
  out_politics: {
    label: "Fora da Política",
    color: "#d46b08",
    icon: <DislikeOutlined />,
  },
};

export const InvoiceStatusList = Object.entries(InvoiceStatusDef).map(
  ([key, value]) => ({
    value: key,
    text: value.label,
    label: value.label,
    color: value.color,
    icon: value.icon,
  })
);
