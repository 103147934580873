// src/pages/SettingsPage.js
import React, { useState } from "react";
import { Card } from "antd";
import { PageContainer } from "@ant-design/pro-components";
import ImportFiles from "./components/import-files";
import ProcessList from "./components/process-list";

const ImportPage: React.FC = () => {
  const [activeTabKey, setActiveTabKey] = useState<string>("import");

  const tabList = [
    {
      key: "import",
      tab: "Importar",
    },
    {
      key: "process",
      tab: "Processos",
    },
  ];

  const contentList: Record<string, React.ReactNode> = {
    import: <ImportFiles />,
    process: <ProcessList />,
  };

  return (
    <PageContainer title={false}>
      <Card
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={(e) => setActiveTabKey(e)}
        styles={{
          body: {
            minHeight: "600px",
          },
        }}
      >
        {contentList[activeTabKey]}
      </Card>
    </PageContainer>
  );
};

export default ImportPage;
